import axios from 'axios'
import ENV from '../../../env'
export default {
  namespaced: true,
  state: {
    clinical_note: {}
  },
  mutations: {
  },
  actions: {
    create ({commit}, payload) {
      return axios.post(`${ENV.simpleNotesUrl}`, payload).then((res) => {
        return res.data
      })
    }
  },
  getters: {
    cie10s (state) { return state.cie10s},
    cie10codes (state) { return state.cie10codes},
    protocol (state) { return state.protocol},
    escalones (state) { return state.escalones},
    libCie10s (state) { return state.libCie10s},
    libCpts (state) { return state.libCpts},
    medicaments (state) { return state.medicaments},
    laboratories (state) { return state.laboratories},
  }
}