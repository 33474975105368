<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Librería CPT</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="libCie10s" :headers="headers"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('Cie10/libCpts')
  },
  computed: {
    libCie10s () {
      return this.$store.getters['Cie10/libCpts']
    }
  },
  data () {
    return {
      headers: [
        {text: 'CPT', value: 'AppHospCpt.name'},
        {text: 'Explicación', value: 'AppHospCpt.explicacion'},
        {text: 'Clave', value: 'AppHospCpt.clave'},
      ]
    }
  }
}
</script>

<style>

</style>