<template>
    <v-dialog
      v-model="dialog"
      width="1000"
    >
      <v-tabs v-model="tabs">
        <v-tab>CIE10</v-tab>
        <v-tab>Protocolo</v-tab>
        <v-tab>LECE</v-tab>
        <v-tab>EPP</v-tab>
        <v-tab>RN</v-tab>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Búsqueda por CIE10 (Dato principal. Signo, síntoma o resultado)
              </v-card-title>

              <v-card-text class="pt-5">
                <v-text-field  label="Palabras Clave" dense outlined v-model="searchField"></v-text-field>
                <v-data-table
                  :items='cie10s'
                  :headers="cie10Headers"
                  item-key="AppHospCie10.id"
                  @click:row="handleRowClick"
                  :single-select="!multipleSelect"
                  :items-per-page="6"
                >
                </v-data-table>
                <v-btn class="green lighten-2" dark>Agregar</v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item><SearchProtocolComponent/></v-tab-item>
          <v-tab-item>
            <EscalonesCie10CPS v-if="lesion" :complication="true" v-model="selectedCie10CPS" :title="ENV.CIE10CPSLesionLCE.dialogTitle" :rules="ENV.CIE10CPSLesionLCE.rules" :cie10cpsId="Array.isArray(ENV.CIE10CPSLesionLCE.base) ? ENV.CIE10CPSLesionLCE.base.map(base => Number(base)) : Number(ENV.CIE10CPSLesionLCE.base)"/>
            <EscalonesCie10CPS v-else-if="causa" :complication="true" v-model="selectedCie10CPS" :title="ENV.CIE10CPSCausaLCE.dialogTitle" :rules="ENV.CIE10CPSCausaLCE.rules" :cie10cpsId="Array.isArray(ENV.CIE10CPSCausaLCE.base) ? ENV.CIE10CPSCausaLCE.base.map(base => Number(base)) : Number(ENV.CIE10CPSCausaLCE.base)"/>
          </v-tab-item>
          <v-tab-item>Por Embarazo Parto o Puerperio</v-tab-item>
          <v-tab-item>Por Recién Nacido</v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="green lighten-2"
          dark
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="white">{{btnIcon}}</v-icon>
        </v-btn>
      </template>
    </v-dialog>
</template>

<script>
import SearchProtocolComponent from './searchProtocol.vue'
import ENV from '../../../env'
import EscalonesCie10CPS from './EscalonesCie10Cps.vue'
export default {
  components: {
    SearchProtocolComponent,
    EscalonesCie10CPS,
  },
  props:{
    lesion: Boolean,
    causa: Boolean,
    btnIcon: {
      type: String,
      default: ''
    },
    value: {},
    multipleSelect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cie10s () {
      return this.$store.getters['Cie10/cie10s']
    }
  },
  watch: {
    searchField () {
      let data = {
        valor: 'AppHospCie10.id',
        texto: 'AppHospCie10.name',
        'campos[AppHospCie10.clave][nombre]': 'Clave',
        'campos[AppHospCie10.name][nombre]': 'CIE10',
        palabras_clave: this.searchField,
        recursive: '-1',
        entity: '',
        json: 1,
      }
      let urlEncodedData = new URLSearchParams(data).toString()
      this.$store.dispatch('Cie10/selectTabla', urlEncodedData)
    }
  },
  methods: {
    openCie10CPSDialog (rules) {
      this.activeCIE1010CPSStringIdentifier = rules.dialogTitle
      this.escalonesCie10Items[this.activeCIE1010CPSStringIdentifier] = []
      this.activeCIE1010CPSId = Array.isArray(rules.base) ? rules.base.map(base => Number(base)) : Number(rules.base)
      this.CIE10CPSPickDialogRules = rules.rules
      this.CIE10CPSPickDialog = true
      this.CIE10CPSPickDialogTitle = rules.dialogTitle
    },
    handleRowClick (cie10) {
      this.$emit('onCie10Selected', cie10)
      this.dialog = false
    },
    handleInput (e) {
      this.$emit('input', this.content)
    }
  },
  data () {
    return {
      selectedCie10CPS: null,
      ENV,
      dialog: false,
      searchField: '',
      tabs: null,
      cie10Headers: [
        {text:"Clave", value:"AppHospCie10.clave"},
        {text:"Cie10", value:"AppHospCie10.name"}
      ]
    }
  }
}
</script>

<style>

</style>