<template>
  <v-container fill-height fluid>
    <v-card max-width="75%" align="center" class="mx-auto pt-10 pb-10 pl-10 pr-10">
      <v-container>
          <v-row>
            <v-col>
              <h1>CODEX MÉDICA</h1>
              <p>SIRES (Sistema de información y registro electrónico en salud) CODEX rp*, es una aplicación clínica programada con menús cortos escalonados, para facilitar la selección y captura de datos, por parte del usuario (médicos, enfermeras, estudiantes, personal de medios auxiliares de diagnóstico y tratamiento, así como de personal administrativo; construidos desde 4 grandes bases de datos: CIE10 CM (Clasificación Internacional de Enfermedades 10ª edición, clínico modificado) para la formulación de problemas (antecedentes, síntomas, signos, resultado de estudios y diagnósticos; el CIE10 PCS (SPC: sistema de procedimientos clínicos), otra base de datos de LABORATORIO y de MEDICAMENTOS; las 3 utilizadas para formulación de planes de diagnóstico y tratamiento; y contextualizados en aplicaciones para consultorio médico; urgencias y hospitalización.</p>
            </v-col>
            <v-col>
            <v-row>
              <v-col>
                  <v-text-field label="Usuario" v-model="Usuario.name" outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Contraseña" v-model="Usuario.password" type=password outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12 class="text-left">
                  <v-btn class="green lighten-2" dark @click="login">Iniciar sesión</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=12 class="text-left">
                  <router-link :to="{name: 'DoctorCreate'}"><small>Crea tu cuenta</small></router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {
    login() {
      this.$store.dispatch('Usuario/login', {Usuario: this.Usuario}).then(() => {
        this.$router.push({name : 'Home'})
      })
    }
  },
  data () {
    return {
      Usuario: {
        name: null,
        password: null
      }
    }
  }
}
</script>

<style>

</style>