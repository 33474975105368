<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Librería CIE10</h2>
      </v-col>
    </v-row>
    <v-row>
        <v-expansion-panels>
          <v-expansion-panel v-for="libCie10 in libCie10s" :key="libCie10.AppHospCie10Codigo.id">
            <v-expansion-panel-header>
              <strong>({{libCie10.AppHospCie10Codigo.code_letters}}) - {{libCie10.AppHospCie10Codigo.name}}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                Calmate vamos empezando
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    console.log('library')
    this.$store.dispatch('Cie10/libCie10s')
  },
  computed: {
    libCie10s () {
      return this.$store.getters['Cie10/libCie10s']
    }
  }
}
</script>

<style>

</style>