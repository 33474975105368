import axios from 'axios'
export default {
  namespaced: true,
  state: {
    vueAgendaEvento: {},
    vueAgendaEventos: [],
  },
  mutations: {
    index (state, payload) {
      state.vueAgendaEventos = payload
    },
    create (state, payload) {
      state.vueAgendaEventos.push(payload)
    },
    update (state, payload) {
      state.vueAgendaEventos = state.vueAgendaEventos.map( evento =>
        evento.VueAgendaEvento.id == payload.VueAgendaEvento.id ? payload : evento
      )
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`https://navegacion.exinnotch.com/api/vue_agenda_eventos${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      }).catch((e) => {
        console.log(e.response ? e.response.data : e)
      });
    },
    create ({commit}, payload) {
      const formData = new FormData()
      // formData.append('escalon_id',223394)
      Object.keys(payload).forEach( key => {
        formData.append(key, payload[key])
      })
      return axios.post(`https://navegacion.exinnotch.com/api/vue_agenda_eventos/create`, formData).then((res) => {
        commit('create', res.data)
        return res.data
      }).catch((e) => {
        console.log(e.response ? e.response.data : e)
      });
    },
    update ({commit}, payload) {
      console.log(payload)
      const formData = new FormData()
      Object.keys(payload.VueAgendaEvento).forEach( key => {
        formData.append(key, payload.VueAgendaEvento[key])
      })
      const config = {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
        }
      }
      const encoded = urlencodeFormData(formData)
      function urlencodeFormData(fd){
        var s = '';
        function encode(s){ return encodeURIComponent(s).replace(/%20/g,'+'); }
        for(var pair of fd.entries()){
            if(typeof pair[1]=='string'){
                s += (s?'&':'') + encode(pair[0])+'='+encode(pair[1]);
            }
        }
        return s;
      }
      return axios.put(`https://navegacion.exinnotch.com/api/vue_agenda_eventos/${payload.VueAgendaEvento.id}/edit`, encoded, config).then((res) => {
        commit('update', res.data)
        return res.data
      }).catch((e) => {
        console.log(e.response ? e.response.data : e)
      });
    },
  },
  getters: {
    vueAgendaEvento: (state) => state.vueAgendaEvento,
    vueAgendaEventos: (state) => state.vueAgendaEventos
  }
}
