import axios from 'axios'
export default {
  namespaced: true,
  state: {
    esConfUsuAccesos: {},
  },
  mutations: {
    esConfUsuAccesos (state, payload) {
      state.esConfUsuAccesos = payload
    },
  },
  actions: {
    index ({commit, state}, payload) {
      return axios.get(`https://navegacion.exinnotch.com/api/ApiEsConfUsuAcceso${payload || ''}`).then((res) => {
        commit('esConfUsuAccesos', res.data)
        console.log(res.data)
        return res.data
      }).catch((e) => {
        
      });
    },
    create ({commit, state}, payload) {
      var formData = new FormData();
      for ( var key in payload.EsREsUsuPermiso ) {
        formData.append(`EsREsUsuPermiso[${key}]`, payload['EsREsUsuPermiso'][key]);
      }
      return axios.post('https://navegacion.exinnotch.com/api/ApiEsConfUsuAcceso/create', formData).then((res) => {
        return res.data
      }).catch((e) => {
        console.log('hi')
        console.log(e.response.data)
      });
    },
    remove ({commit, state}, payload) {
      return axios.delete(`https://navegacion.exinnotch.com/api/ApiEsConfUsuAcceso/${payload ? payload : ''}`).then((res) => {
        return res.data
      }).catch((e) => {
        console.log('hi')
        console.log(e.response.data)
      });
    },
  },
  getters: {
    esConfUsuAccesos (state) { return state.esConfUsuAccesos}
  }
}
