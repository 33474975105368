<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <h2>Lista de notas del encuentro</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="green lighten-2" dark>Agregar nueva nota</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h4>{{entity.name}}</h4>
          <v-data-table :items="notes" :headers="headers" :sort-by="['date']" :sort-desc="[true]">
            <template v-slot:item.actions="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="goToNote(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-stethoscope</v-icon>
                </template>
                <span>Ver nota</span>
              </v-tooltip>
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    goToNote (item) {
      console.log(item)
      this.$router.push({name: 'ClinicalEntriesFreeCreate'})
    },
  },
  computed: {
    entity() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      return entity && entity.id ? entity : {name: ""}
    },
    thisStablishmentEncounters () {
      return this.encounters.filter( encounter => this.thisStablishment.id == encounter.stablishment_id)
    },
    otherStablishmentsEncounters () {
      return this.encounters.filter( encounter => this.thisStablishment.id != encounter.stablishment_id)
    },
    thisStablishment () {
      return {
        id: 1
      }
    }
  },
  data () {
    return {
      tab: null,
      headers: [
        {value: 'date', text: 'Fecha'},
        {value: 'name', text: 'Tipo'},
        {value: 'stablishment', text: 'Establecimiento'},
        {value: 'responsible.name', text: 'Responsable'},
        {value: 'specialty', text: 'Especialidad'},
        {value: 'actions', text: 'Acciones'},
      ],
      notes: [
        {name: 'Ingreso preoperatoria', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Preanestésica', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Operatoria', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Postanestésica', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Reingreso al piso', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Nota de evolución quirúrgica', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Nota de enfermería quirúrgica', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Notas de alta', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 1, date: '11/11/2021 11:11:00'},
      ]
    }
  }
}
</script>

<style>

</style>