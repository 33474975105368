<template>
  <div class="home">
    <v-container>
      <v-row v-if="user">
        <v-col cols=6 class="text-left">
          ¡Bienvenido Dr. {{user.nombre_completo}}!
        </v-col>
        <v-col cols=6 class="text-right">
          <a @click="logout">Cerrar sesión</a>
        </v-col>
      </v-row>
      <v-row v-if="user">
        <v-col lg=4 cols=6 v-for="entidad in user.EsEntidadSalud" :key="entidad.EsEntidadSalud.id">
          <v-card
            @click="goToAgenda(entidad)"
            max-height='500px'
            height='450px'
            >
            <v-card-title style="height: 100px" primary-title class="h-20 text-wrap justify-center">
              <h4>{{entidad.EsEntidadSalud.name}}</h4>
            </v-card-title>
            <v-card-text>
              <v-icon size="250">mdi-hospital-building</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods : {
    logout () {
      this.$router.push({name : 'Login'})
    },
    goToAgenda (entity) {
      this.$store.commit('Usuario/selectedEntity', entity)
      this.$router.push({name: 'AgendaIndex'})
    }
  },
  mounted () {
    if(Object.keys(this.$store.getters['Usuario/user']).length == 0) {
      this.$router.push({name : 'Login'})
    }
  },
  computed: {
    user () {
      return this.$store.getters['Usuario/user'] ? this.$store.getters['Usuario/user'].Usuario : []
    }
  }
}
</script>
