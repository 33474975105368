<template>
  <v-container fluid>
    <v-dialog
      v-model="CIE10CPSPickDialog"
      width="1000"
    >
       <EscalonesCie10CPS v-model="selectedCie10CPS" :title="CIE10CPSPickDialogTitle" :rules="CIE10CPSPickDialogRules" :cie10cpsId="activeCIE1010CPSId"/>
    </v-dialog>
    <v-row>
      <v-col>
        <h2>Nota Clínica de Consulta Externa</h2>
        <h3 v-if="activeProblem !== false">Problema activo: {{activeProblem.AppHospCie10.name}}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 class="text-center">
        <v-tabs grow>
          <v-tab>
            <h3>Formulación de problemas</h3>
          </v-tab>
          <v-tab>
            <h3>Decisión Médica</h3>
          </v-tab>
          <v-tab-item>
            <v-row>
              <v-col lg=6>
                <v-row>
                  <v-col cols=10>
                    <h4>Lista de problemas</h4>
                    <template>
                      <v-tabs v-model="tabDiagnoses">
                        <v-tab>Activos</v-tab>
                        <v-tab>Inactivos</v-tab>
                        <v-tab>Antecedentes</v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tabDiagnoses">
                        <!-- lista de problemas -->
                        <v-tab-item>
                          <v-card flat>
                              <v-data-table
                              show-select
                              single-select
                              v-model="selectedActiveProblem"
                              :headers="headerListaProblemas"
                              item-key="AppHospCie10.id"
                              :items="activeProblems"
                              :header-props = "{
                                sortByText: 'Ordenar por',
                              }"
                              :footer-props="{
                                nextIcon: 'mdi-plus',
                                  'items-per-page-text':'Problemas activos por página'
                              }"
                              :items-per-page="5"
                              class="elevation-1"
                            >
                              <template slot="no-data">
                                <div>No hay problemas disponibles</div>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-data-table
                              :headers="headerListaProblemas"
                              v-model="selectedInactiveProblem"
                              :items="inactiveProblems"
                              :header-props = "{
                                sortByText: 'Ordenar por',
                              }"
                              :footer-props="{
                                nextIcon: 'mdi-plus',
                                  'items-per-page-text':'Problemas Problemas Inactivos por Página'
                              }"
                              :items-per-page="5"
                              class="elevation-1"
                            >
                              <template slot="no-data">
                                <div>No hay problemas disponibles</div>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-data-table
                              :headers="headerListaProblemas"
                              v-model="selectedAntecedent"
                              :items="antecedents"
                              :header-props = "{
                                sortByText: 'Ordenar por',
                              }"
                              :footer-props="{
                                nextIcon: 'mdi-plus',
                                  'items-per-page-text':'Problemas Problemas Inactivos por Página'
                              }"
                              :items-per-page="5"
                              class="elevation-1"
                            >
                              <template slot="no-data">
                                <div>No hay problemas disponibles</div>
                              </template>
                            </v-data-table>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </template>
                  </v-col>
                  <v-col cols=1>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <SearchCie10Component :lesion="true" @onCie10Selected = "onProblemCie10Selected" v-bind="attrs" v-on="on" :btnIcon="'mdi-plus'"/>
                      </template>
                      <span>Añadir problema</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                      <v-btn small v-bind="attrs" v-on="on" color="blue lighten-2"><v-icon color="white">mdi-alpha-h-box-outline</v-icon></v-btn>
                      </template>
                      <span>Ver Histórico</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" small color="red lighten-2" class="mr-2"><v-icon color="white">mdi-delete</v-icon></v-btn>
                      </template>
                      <span>Eliminar problema</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=12>
                      <v-tabs v-model="tabFormulation">
                        <v-tab>Datos Subjetivos</v-tab>
                        <v-tab>Datos Objetivos</v-tab>
                        <v-tab>Diagnósticos</v-tab>
                        <v-tabs-items v-model="tabFormulation">
                          <!--Síntomas-->
                          <v-tab-item>
                            <v-row>
                              <v-col cols=10>
                                <template>
                                  <v-data-table
                                    :headers="headerFormulationSymptoms"
                                    :items="formulationSymptoms"
                                    :header-props = "{
                                      sortByText: 'Ordenar por',
                                    }"
                                    :footer-props="{
                                      nextIcon: 'mdi-plus',
                                        'items-per-page-text':'Síntomas por página'
                                    }"
                                    :items-per-page="5"
                                    class="elevation-1"
                                  >
                                    <template slot="no-data">
                                      <div>No hay síntomas disponibles</div>
                                    </template>
                                  </v-data-table>
                                </template>
                              </v-col>
                              <v-col cols=1>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <SearchCie10Component :causa="true" @onCie10Selected = "onFormulationSelected" v-bind="attrs" v-on="on" :btnIcon="'mdi-plus'"/>
                                  </template>
                                  <span>Añadir Síntoma</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="red lighten-2" class="mr-2" v-on="on" v-bind="attrs"><v-icon color="white">mdi-sleep</v-icon></v-btn>
                                  </template>
                                  <span>Inactivar</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-tab-item>
                          <v-tab-item>
                            <!--Signos-->
                            <v-row>
                              <v-col cols=10>
                                <v-tabs v-model="tabFormulationTypes">
                                  <v-tab>Exploración física</v-tab>
                                  <v-tab>Somatometría y signos vitales</v-tab>
                                  <v-tab>Hallazgos de laboratorio</v-tab>
                                  <v-tab>Hallazgos de imagen</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tabFormulationTypes">
                                  <v-tab-item>
                                    <!--exploración física-->
                                    <v-data-table
                                      :headers="headerSignPhysicalExamitation"
                                      :items="signPhysicalExamitation"
                                      :header-props = "{
                                        sortByText: 'Ordenar por',
                                      }"
                                      :footer-props="{
                                        nextIcon: 'mdi-plus',
                                          'items-per-page-text':'Signos por página'
                                      }"
                                      :items-per-page="5"
                                      class="elevation-1"
                                    >
                                      <template slot="no-data">
                                        <div>No hay signos disponibles</div>
                                      </template>
                                    </v-data-table>
                                  </v-tab-item>
                                  <v-tab-item>
                                     <!--Signos vitales-->
                                    <v-data-table
                                      :headers="headerSignPhysicalExamitation"
                                      :items="signSomatometries"
                                      :header-props = "{
                                        sortByText: 'Ordenar por',
                                      }"
                                      :footer-props="{
                                        nextIcon: 'mdi-plus',
                                          'items-per-page-text':'Signos por página'
                                      }"
                                      :items-per-page="5"
                                      class="elevation-1"
                                    >
                                      <template slot="no-data">
                                        <div>No hay signos disponibles</div>
                                      </template>
                                    </v-data-table>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <!--Halalzgos de laboratorio-->
                                    <v-data-table
                                      :headers="headerSignPhysicalExamitation"
                                      :items="signLaboratoryFindings"
                                      :header-props = "{
                                        sortByText: 'Ordenar por',
                                      }"
                                      :footer-props="{
                                        nextIcon: 'mdi-plus',
                                          'items-per-page-text':'Hallazgos por página'
                                      }"
                                      :items-per-page="5"
                                      class="elevation-1"
                                    >
                                      <template slot="no-data">
                                        <div>No hay hallazgos disponibles</div>
                                      </template>
                                    </v-data-table>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <!--Halalzgos de imagen-->
                                    <v-data-table
                                      :headers="headerSignPhysicalExamitation"
                                      :items="signImageFindings"
                                      :header-props = "{
                                        sortByText: 'Ordenar por',
                                      }"
                                      :footer-props="{
                                        nextIcon: 'mdi-plus',
                                          'items-per-page-text':'Hallazgos por página'
                                      }"
                                      :items-per-page="5"
                                      class="elevation-1"
                                    >
                                      <template slot="no-data">
                                        <div>No hay hallazgos disponibles</div>
                                      </template>
                                    </v-data-table>
                                  </v-tab-item>
                                </v-tabs-items>
                              </v-col>
                              <v-col cols=1>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <SearchCie10Component :causa="true" @onCie10Selected = "onFormulationSelected" v-bind="attrs" v-on="on" :btnIcon="'mdi-plus'"/>
                                    <v-btn small color="green lighten-2" class="mr-2" v-bind="attrs" v-on="on"><v-icon color="white">mdi-plus</v-icon></v-btn>
                                  </template>
                                  <span>Añadir Signo</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="green lighten-2" class="mr-2" v-bind="attrs" v-on="on"><v-icon color="white">mdi-equal</v-icon></v-btn>
                                  </template>
                                  <span>Igual</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="green lighten-2" class="mr-2" v-bind="attrs" v-on="on"><v-icon color="white">mdi-thumb-up</v-icon></v-btn>
                                  </template>
                                  <span>Mejor</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="green lighten-2" class="mr-2" v-bind="attrs" v-on="on"><v-icon color="white">mdi-thumb-down</v-icon></v-btn>
                                  </template>
                                  <span>Peor</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="red lighten-2" class="mr-2" v-on="on" v-bind="attrs"><v-icon color="white">mdi-alert-decagram</v-icon></v-btn>
                                  </template>
                                  <span>Reaparición</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="red lighten-2" class="mr-2" v-on="on" v-bind="attrs"><v-icon color="white">mdi-sleep</v-icon></v-btn>
                                  </template>
                                  <span>Desapareció</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="red lighten-2" class="mr-2" v-on="on" v-bind="attrs"><v-icon color="white">mdi-delete</v-icon></v-btn>
                                  </template>
                                  <span>Eliminar</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-tab-item>
                          <v-tab-item>
                            <v-row>
                              <!--Diagnósticos-->
                              <v-col cols=10>
                                <template>
                                  <v-data-table
                                    :headers="headerFormulationDiagnosis"
                                    :items="formulationDiagnosis"
                                    :header-props = "{
                                      sortByText: 'Ordenar por',
                                    }"
                                    :footer-props="{
                                      nextIcon: 'mdi-plus',
                                        'items-per-page-text':'Diagnósticos por página'
                                    }"
                                    :items-per-page="5"
                                    class="elevation-1"
                                  >
                                    <template slot="no-data">
                                      <div>No hay diagnósticos disponibles</div>
                                    </template>
                                  </v-data-table>
                                </template>
                              </v-col>
                              <v-col cols=1>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <SearchCie10Component @onCie10Selected = "onFormulationSelected" v-bind="attrs" v-on="on" :btnIcon="'mdi-plus'"/>
                                    <v-btn small color="green lighten-2" class="mr-2"><v-icon color="white" v-bind="attrs" v-on="on">mdi-plus</v-icon></v-btn>
                                  </template>
                                  <span>Añadir Diagnóstico</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="orange lighten-2" class="mr-2" small dark v-bind="attrs" v-on="on"><v-icon color="white">mdi-magnify</v-icon></v-btn>
                                  </template>
                                  <span>Diagnóstico por criterio objetivo</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="orange lighten-2"
                                      class="mr-2"
                                      small
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="white">mdi-file-edit-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Diagnóstico por criterio clínico</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="orange lighten-2"
                                      class="mr-2"
                                      small
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="white">mdi-help</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Diagnóstico probable o diagnóstico diferencial</span>
                                </v-tooltip>
                                <v-btn small color="red lighten-2" class="mr-2"><v-icon color="white">mdi-delete</v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-tabs>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols=6>
                <v-row>
                  <v-col cols=12>
                    <h4>Comentario Clínico</h4>
                  </v-col>
                  <v-col cols=12>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-textarea
                            outlined
                            label="Padecimiento actual, antecedentes, hallazgos clínicos y paraclínicos"
                            value=""
                            v-bind="attrs"
                            v-on="on"
                            hint="Hint text"
                          ></v-textarea>
                        </template>
                        <span>Padecimiento actual, antecedentes, hallazgos clínicos y paraclínicos</span>
                      </v-tooltip>
                    </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col lg=12>
                <template>
                  <v-tabs v-model="tabPlans" grow>
                    <v-tab>Plan Diagnóstico</v-tab>
                    <v-tab>Plan Terapéutica</v-tab>
                    <v-tab>Plan Educativo</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabPlans">
                    <v-tab-item>
                      <template>
                        <v-tabs v-model="tabPlansDiagnosis">
                          <v-tab>Laboratorio</v-tab>
                          <v-tab>Patología</v-tab>
                          <v-tab>Imagen</v-tab>
                          <v-tab>Endoscopia</v-tab>
                          <v-tab>Medicina Nuclear</v-tab>
                          <v-tab>Interconsulta o referencia</v-tab>
                          <v-tab>Audiología</v-tab>
                          <v-tab>Pruebas Psicológicas</v-tab>
                          <v-tab>Pruebas Gabinete</v-tab>
                          <v-tab>Otro</v-tab>
                        </v-tabs>
                      </template>
                      <v-tabs-items v-model="tabPlansDiagnosis">
                        <!--Estudios de laboratorio-->
                        <v-tab-item>
                          Estudios de laboratorio
                        </v-tab-item>
                        <!--Estudios de Patología-->
                        <DiagnosisTabItem :items="escalonesCie10Items[ENV.CIE10CPSPatologiaCondiciones.dialogTitle] ? escalonesCie10Items[ENV.CIE10CPSPatologiaCondiciones.dialogTitle] : []" @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSPatologiaCondiciones"/>
                        <!--EsTudios de imagen -->
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSImagenCondiciones"/>
                        <!--Estudios de Endoscopia-->
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSEndoscopiaCondiciones"/>
                        <!--EsTudios de Medicina Nuclear -->
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSMedicinaNuclearCondiciones"/>
                        <!--Referencia-->
                        <v-tab-item>
                          <v-btn>
                          Interconsulta o Referencia
                          </v-btn>
                        </v-tab-item>
                        <!--Audiologia-->
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSAudiologiaCondiciones"/>
                        <!--Pruebas Psicológicas-->
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSPruebasPsicologicasCondiciones"/>
                        <!--Gabinete-->
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSSistemasFisiologicosCondiciones"/>
                      </v-tabs-items>
                    </v-tab-item>
                    <v-tab-item>
                      <template>
                        <v-tabs v-model="tabPlansTherapheutic">
                          <v-tab>Farmacia</v-tab>
                          <v-tab>Cirugía</v-tab>
                          <v-tab>Obstetricia</v-tab>
                          <v-tab>Colocación y retiro</v-tab>
                          <v-tab>Administación de sustancias (quimioterapia)</v-tab>
                          <v-tab>Terapias básicas</v-tab>
                          <v-tab>Medicina Transfusional</v-tab>
                          <v-tab>Irrigación</v-tab>
                          <v-tab>Medicina Nuclear</v-tab>
                          <v-tab>Radioterapia</v-tab>
                          <v-tab>Terapias especiales</v-tab>
                          <v-tab>Terapias Medicina Física y Rehabilitación</v-tab>
                        </v-tabs>
                      </template>
                      <v-tabs-items v-model="tabPlansTherapheutic">
                        <v-tab-item>Farmacia</v-tab-item>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSTerapeuticCirugiaCondiciones"/>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSTerapeuticObstetriciaCondiciones"/>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSTerapeuticColocacion"/>
                        <v-tab-item>Quimioterapia</v-tab-item>
                        <v-tab-item>Terapias básicas</v-tab-item>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSTerapeuticTransfusionesCondiciones"/>
                        <v-tab-item>Irrigacion</v-tab-item>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSTerapeuticNuclearMedicine"/>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSTerapeuticRadioterapia"/>
                        <v-tab-item>Terapias especiales</v-tab-item>
                        <DiagnosisTabItem @openCie10CPSDialog="openCie10CPSDialog" :condiciones="ENV.CIE10CPSMedicinaFisicayRehabilitación"/>
                      </v-tabs-items>
                    </v-tab-item>
                    <v-tab-item>
                      <template>
                        <v-tabs v-model="tabPlansEducative">
                          <v-tab>Paciente</v-tab>
                          <v-tab>Médico</v-tab>
                        </v-tabs>
                      </template>
                    </v-tab-item>
                  </v-tabs-items>
                </template>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchCie10Component from '../../globals/searchCie10.vue'
import DiagnosisTabItem from './DiagnosisTabItem.vue'
import EscalonesCie10CPS from '../../globals/EscalonesCie10Cps.vue'
import ENV from '../../../../env'
// import SearchCie10SystemsComponent from '../../globals/searchCie10Systems.vue'
export default {
  mounted () {
    // const elem = document.documentElement
    // elem.requestFullscreen();
  },
  components: {
    SearchCie10Component,
    EscalonesCie10CPS,
    DiagnosisTabItem
    // SearchCie10SystemsComponent,
  },
  watch: {
    activeProblems () {
      console.log('activeProblems changed')
      this.$store.dispatch('ClinicalNote/create')
    },
    inactiveProblems () {
      console.log('inactiveProblems changed')
    },
    antecedents () {
      console.log('antecedents changed')
    },
    selectedCie10CPS () {
      const copyEscalonesCie10Items = JSON.parse(JSON.stringify(this.escalonesCie10Items))
      copyEscalonesCie10Items[this.activeCIE1010CPSStringIdentifier].push(this.selectedCie10CPS)
      this.$set(this, 'escalonesCie10Items', copyEscalonesCie10Items)
    }
  },
  methods: {
    openCie10CPSDialog (rules) {
      this.activeCIE1010CPSStringIdentifier = rules.dialogTitle
      this.escalonesCie10Items[this.activeCIE1010CPSStringIdentifier] = []
      this.activeCIE1010CPSId = Array.isArray(rules.base) ? rules.base.map(base => Number(base)) : Number(rules.base)
      this.CIE10CPSPickDialogRules = rules.rules
      this.CIE10CPSPickDialog = true
      this.CIE10CPSPickDialogTitle = rules.dialogTitle
    },
    onProblemCie10Selected(item) {
      // activos
      if (this.tabDiagnoses == 0) {
        this.activeProblems.push(item)
      }
      //inactivos
      else if (this.tabDiagnoses == 1) {
        this.inactiveProblems.push(item)
      }
      // antecedentes
      else if (this.tabDiagnoses == 2) {
        this.antecedents.push(item)
      }
    },
    onFormulationSelected (item) {
      // Síntomas
      if (this.tabFormulation == 0) {
        this.formulationSymptoms.push(item)
      }
      //Signos
      else if (this.tabFormulation == 1) {
        //exploración física
        if (this.tabFormulationTypes == 0) {
          this.signPhysicalExamitation.push(item)
        }
        //somatometría
        if (this.tabFormulationTypes == 1) {
          this.signSomatometries.push(item)
        }
        //hallazgos laboratorio
        if (this.tabFormulationTypes == 2) {
          this.signLaboratoryFindings.push(item)
        }
        //hallazgos imagen
        if (this.tabFormulationTypes == 3) {
          this.signImageFindings.push(item)
        }
      }
      else if (this.tabFormulation == 2) {
        this.formulationDiagnosis.push(item)
      }
      //Diagnósticos
      else if (this.tabFormulation == 2) {
        this.formulationDiagnosis.push(item)
      }
    }
  },
  computed: {
    activeProblem () {
      if (this.selectedActiveProblem.length > 0){
        return this.selectedActiveProblem[0]
      }
      return false
    }
  },
  data () {
    return {
      activeCIE1010CPSStringIdentifier: null,
      CIE10CPSPickDialogRules: null,
      CIE10CPSPickDialogTitle: '',
      CIE10CPSPickDialog: null,
      activeCIE1010CPSId: null,
      CIE10CPSPickDialogExcludes: [],
      CIE10CPSPickDialogIncludes: [],
      CIE10CPSPickDialogBaseCode: null,
      cie10cpsId: null,
      ENV: ENV,
      // Lista de problemas
      headerListaProblemas : [{text: 'Problema', value: 'AppHospCie10.name', sortable: true}],
      selectedActiveProblem: [],
      activeProblems: [],
      inactiveProblems: [],
      selectedInactiveProblem: [],
      selectedAntecedent: [],
      antecedents: [],
      // formulación del problema
      tabFormulationTypes: null,
      //síntomas
      headerFormulationSymptoms: [{text: 'Nombre', value: 'AppHospCie10.name', sortable: true}],
      formulationSymptoms: [],
      formulationSigns: [],
      //signos
      headerSignPhysicalExamitation: [{text: 'Nombre', value: 'AppHospCie10.name', sortable: true}],
      signPhysicalExamitation: [],
      signSomatometries: [],
      signLaboratoryFindings: [],
      signImageFindings: [],
      //Diagnósticos
      headerFormulationDiagnosis: [{text: 'Diagnóstico', value: 'AppHospCie10.name', sortable: true}],
      formulationDiagnosis: [],

      tabFormulation: null,
      tabDiagnoses: null,
      tabPlans: null,
      tabPlansDiagnosis: null,
      tabPlansTherapheutic: null,
      tabPlansEducative: null,


      selectedCie10CPS: {},
      escalonesCie10Items: {
      },
    }
  }
}
</script>

<style>

</style>