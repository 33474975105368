
import axios from 'axios'
export default {
  namespaced: true,
  state: {
    modelName: 'AppCatProtocolo',
    protocol: {},
    protocols: [],
    escalones: {
      signs: [],
      symptoms: [],
      diagnosis: [],
    }
  },
  mutations: {
    protocol (state, payload) {
      state.protocol = payload
    },
    protocols (state, payload) {
      state.protocols = payload
    },
    escalones (state, payload) {
      state.escalones = payload
    }
  },
  actions: {
    create ({commit, state}, payload) {
      return axios.post('https://navegacion.exinnotch.com/api/protocols', payload).then((res) => {
        commit('protocol', res.data)
        return res.data
      });
    },
    protocols ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/protocols/'+payload).then((res) => {
        commit('protocols', res.data)
      });
    },
    protocol ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/protocols/'+payload).then((res) => {
        commit('protocol', res.data)
      });
    },
    escalones ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/escalones_protocolos/'+payload).then((res) => {
        commit('escalones', res.data)
      });
    },
    edit ({commit, state}, payload) {
      return axios.put(`https://navegacion.exinnotch.com/api/protocols/${payload.id}/edit`, payload.model).then((res) => {
        commit('protocol', res.data)
      });
    }
  },
  getters: {
    protocols (state) { return state.protocols},
    protocol (state) { return state.protocol},
    escalones (state) { return state.escalones},
  }
}