<template>
  <v-container>
      <v-dialog
      transition="dialog-bottom-transition"
      max-width="1000"
      v-model="createPersonalModal"
      >
      <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
            color="blue lighten-2"
            dark
          > AGREGAR PERSONAL: {{currentPersonalTypeCreate}}
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols=4><v-text-field v-model="selectedPersonal.name" outlined label="Nombre"></v-text-field></v-col>
              <v-col cols=4><v-text-field v-model="selectedPersonal.last_name" outlined label="Apellido paterno"></v-text-field></v-col>
              <v-col cols=4><v-text-field v-model="selectedPersonal.second_last_name" outlined label="Apellido materno"></v-text-field></v-col>
              <v-col cols=12 v-if="!hideUserPersonalTable">
                <v-data-table
                  :items="usersForTable"
                  :headers="[
                    {text: 'Nombre', value: 'Usuario.nombre_completo'}
                  ]"
                  @click:row="defineUserSchedule"
                >
                </v-data-table>
              </v-col>
              <v-col v-else>
                <h3>Horario de atención para el usuario</h3>
                <h3>{{selectedUserPermission ? selectedUserPermission.Usuario.nombre_completo : ''}}</h3>
                <v-row>
                  <v-col cols=6>
                    <h4 class="mx auto mt-5">Duración primera vez</h4>
                    <v-select v-model="creatingPermission.first_time_mins" :items="timeOptions" item-value="value" item-text="text"/>                
                  </v-col>
                  <v-col cols=6>
                    <h4 class="mx auto mt-5">Duración consulta subsecuente</h4>
                    <v-select v-model="creatingPermission.subsecuent_time_mins" :items="timeOptions" item-value="value" item-text="text"/>  
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Lunes</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.monday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.monday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Martes</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.tuesday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.tuesday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Miercoles</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.wednesday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.wednesday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Jueves</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.thursday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.thursday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Viernes</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.friday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.friday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Sabado</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.saturday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.saturday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Domingo</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.sunday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.sunday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn class="green lighten-2" dark @click="addUserPermission(selectedUserPermission)">Añadir permiso</v-btn>
                  </v-col>
                </v-row>
                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
              <v-btn
                @click="dialog.value = false"
              >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1000"
      v-model="directoryDialog"
    >
    <!-- Dialogo de configuración -->
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
            color="blue lighten-2"
            dark
          >DIRECTORIO</v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-tabs v-model="directoryTabs">
                    <v-tab>Personas</v-tab>
                    <v-tab>Empresas</v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-tabs-items v-model="directoryTabs">
                    <v-tab-item>
                      <v-row v-if="!addingPersonal">
                        <v-col cols=12 class="text-left">
                          <v-btn class="green lighten" dark @click="addingPersonal = true">Agregar</v-btn>
                        </v-col>
                        <v-col cols=12 class="text-left">
                          <h4>Buscador</h4>
                        </v-col>
                        <v-col col-lg=4 class="text-left">
                          <v-text-field outlined label="Nombres" v-model="personsDirectoryNameSearch"></v-text-field>
                        </v-col>
                        <v-col cols=4 class="text-left">
                          <v-text-field outlined label="Teléfono" v-model="personsDirectoryPhoneSearch"></v-text-field>
                        </v-col>
                        <v-col cols=4 class="text-left">
                          <v-text-field outlined label="Email" v-model="personsDirectoryEmailSearch"></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-data-table :headers="[
                              {text:'Nombre', value:'name'},
                              {text:'Teléfono', value:'telephone'},
                              {text:'Email', value:'email'},
                            ]"
                            :items="personalSearch"
                            :hide-default-footer="true"
                          ></v-data-table>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols=12 class="text-left">
                          <v-btn class="blue lighten-2" dark @click="addingPersonal = false">Atras</v-btn>
                        </v-col>
                        <v-col cols=2>
                          <v-text-field outlined v-model="personalCreating.prefix" placeholder="Prefijo"></v-text-field>
                        </v-col>
                        <v-col cols=10>
                          <v-text-field outlined v-model="personalCreating.name" placeholder="Nombre"></v-text-field>
                        </v-col>
                        <v-col cols=12>
                          <v-text-field outlined v-model="personalCreating.telephone" placeholder="Teléfono"></v-text-field>
                          <v-icon>mdi-plus</v-icon>
                        </v-col>
                        <v-col>
                          <v-text-field outlined v-model="personalCreating.email" placeholder="Correo electrónico"></v-text-field>
                          <v-text-field outlined v-model="personalCreating.address" placeholder="Domicilio del consultorio"></v-text-field>
                          <v-text-field outlined v-model="personalCreating.specialty" placeholder="Especialidad"></v-text-field>
                        </v-col>
                        <v-col cols=12 class="text-right">
                          <v-btn class="blue lighten-2" dark @click="() => {personal.push(personalCreating); addingPersonal = false;}">Agregar</v-btn>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-row v-if="!addingCompany">
                        <v-col cols=12 class="text-left">
                          <v-btn class="green lighten" dark @click="addingCompany = true">Agregar</v-btn>
                        </v-col>
                        <v-col cols=12>
                          <v-data-table :headers="[
                              {text:'Razón social', value:'official_name'},
                              {text:'Nombre', value:'name'},
                              {text:'Teléfono', value:'telephone'},
                              {text:'Vínculo', value:'link_name'},
                            ]"
                            :items="companies"
                            :hide-default-footer="true"
                          ></v-data-table>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols=12 class="text-left">
                          <v-btn class="blue lighten-2" dark @click="addingCompany = false">Atras</v-btn>
                        </v-col>
                        <v-col>
                          <v-text-field outlined v-model="companyCreating.official_name" placeholder="Razón social"></v-text-field>
                          <v-text-field outlined v-model="companyCreating.name" placeholder="Nombre"></v-text-field>
                          <v-text-field outlined v-model="companyCreating.telephone" placeholder="Teléfono"></v-text-field>
                          <v-text-field outlined v-model="companyCreating.email" placeholder="Correo electrónico"></v-text-field>
                          <v-text-field outlined v-model="companyCreating.address" placeholder="Dirección"></v-text-field>
                          <v-autocomplete outlined v-model="companyCreating.vinculo" placeholder="Vínculo" :items="personal" item-text="name" item-value="name"></v-autocomplete>
                        </v-col>
                        <v-col cols=12 class="text-right">
                          <v-btn class="blue lighten-2" dark @click="() => {personal.push(companyCreating); addingCompany = false;}">Agregar</v-btn>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>B</v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>C</v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              @click="dialog.value = false"
            >Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="appointmentDialog"
    >
    <!-- Dialogo de paciente -->
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
            color="green lighten-2"
            dark
          >Agenda la cita de tu paciente</v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col cols=5><v-text-field v-model="selectedPatient.name" outlined label="Nombre"></v-text-field></v-col>
                    <v-col cols=4><v-text-field v-model="selectedPatient.last_name" outlined label="Apellido paterno"></v-text-field></v-col>
                    <v-col cols=3><v-text-field v-model="selectedPatient.second_last_name" outlined label="Apellido materno"></v-text-field></v-col>
                    <h4>Resultados</h4>
                    <v-col cols=12 style="overflow: auto; height: 35vh;">
                      <v-data-table
                        :items="patients"
                        hide-default-header
                        hide-default-footer
                        @click:row="selectBookPatient"
                        :headers="[
                          {text: 'Nombre', value: 'HosPaciente.nombre_completo'},
                          {text: 'Fecha de nac.', value: 'HosPaciente.fecha_nacimiento'},
                          {text: 'Lugar de nac.', value: 'AppCatEstadoNacimiento.name'},
                        ]"
                      >
                      </v-data-table>
                    </v-col>
                    <v-col cols=12><v-text-field v-model="selectedPatient.telephone" outlined label="Telefono"></v-text-field></v-col>
                    <v-col cols=12><v-text-field v-model="selectedPatient.email" outlined label="Email"></v-text-field></v-col>
                    <v-col cols=12>
                      <DMYDialog v-model="selectedPatient.date" :label="'Reagendar a fecha'"/>
                      <v-select 
                        outlined
                        v-model="selectedPatient.time"
                        :label="'Reagendar a hora'"
                        :items="horarios"
                        item-value="time"
                        item-text="time"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              @click="dialog.value = false"
            >Cerrar</v-btn>
            <v-btn
              class="green lighten-2"
              dark
              v-if="edittingEventId"
              @click="editEvent()"
            >Editar</v-btn>
            <v-btn
              class="green lighten-2"
              dark
              v-else
              @click="bookPatient()"
            >Agendar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row>
      <v-col cols=12>
        <h2>AGENDA</h2>
        <h4>{{entity.name}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols></v-col>
      <v-col cols=7>
        <v-btn class="blue lighten-2" small dark @click="()=> directoryDialog = true">Directorio</v-btn>
        <v-btn class="green lighten-2" small dark :to="{name: 'PatientIndex'}">Lista de pacientes</v-btn>
        <v-btn class="green lighten-2" small dark :to="{name: 'PatientIndex'}">Programar evento</v-btn>
        <v-icon @click="$router.push({name:'HealthStablishmentConfiguration'})">mdi-cog-outline</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3><v-icon @click="prev">mdi-chevron-left</v-icon>{{months[parseInt(parsedDate.month)].text}}<v-icon @click="next">mdi-chevron-right</v-icon></h3>
        <v-sheet height="300">
          <v-calendar
            ref="calendar"
            v-model="daySelected"
            color="primary"
            :events="[]"
            @click:date="dayClicked"
          ></v-calendar>
        </v-sheet>
        <v-row>
          <v-col>
            <h3>Personal</h3>
            <v-tabs v-model="personalTabs">
              <v-tab>Médicos</v-tab>
              <v-tab>Enfermeras</v-tab>
              <v-tab>Auxiliares</v-tab>
            </v-tabs>
            <v-tabs-items v-model="personalTabs">
              <v-tab-item>
                <v-btn class="green lighten-2" dark @click="openAddPersonalForm('MÉDICO')">Añadir médico</v-btn>
              </v-tab-item>
              <!--Enfermeras-->
              <v-tab-item>
                <v-btn class="green lighten-2" dark @click="openAddPersonalForm">Añadir enfermera</v-btn>
                <v-data-table :items="entityPersonal.enfermeras" :headers="[
                    {text: 'Nombre', value: 'full_name'},
                    {text: 'Especialidad', value: 'specialty'},
                  ]"
                  :item-class="entityPersonalItemRowBackground"
                  @click:row="selectedEntityPersonal"
                  :hide-default-footer="true"
                  :items-per-page="-1"
                >
                  <template
                    v-slot:footer
                  >
                  </template>
                </v-data-table>
              </v-tab-item>
              <!--Auxiliares-->
              <v-tab-item>
                <v-btn class="green lighten-2" dark>Añadir auxiliar</v-btn>
                <v-data-table :items="entityPersonal.auxiliary" :headers="[
                    {text: 'Nombre', value: 'full_name'},
                    {text: 'Especialidad', value: 'specialty'},
                  ]"
                  :item-class="entityPersonalItemRowBackground"
                  @click:row="selectedEntityPersonal"
                  :hide-default-footer="true"
                  :items-per-page="-1"
                >
                  <template
                    v-slot:footer
                  >
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <h3>{{`${parsedDate.day} de ${months[parseInt(parsedDate.month)].text} del ${parsedDate.year}`}}</h3>
        <v-row>
          <v-col>
            <v-checkbox label="Ver horarios agendados"></v-checkbox>
          </v-col>
          <v-col>
            <v-checkbox v-model="isConcentrated" label="Agenda concentrada"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="isConcentrated">
          <v-col>
            De: <v-text-field small outlined class="shrink"></v-text-field>
          </v-col>
          <v-col>
            Hasta: <v-text-field outlined></v-text-field>
          </v-col>
        </v-row>
        <v-tabs v-model="tabsCanceled">
          <v-tab>Citas</v-tab>
          <v-tab>Canceladas</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabsCanceled">
          <v-tab-item>
            <v-sheet
              height=500px
              style="overflow: auto"
            >
              <v-data-table :items="horarios" :headers="[
                {text: 'Hora', value: 'time'},
                {text: 'Evento', value: 'name'},
                {text: 'Acciones', value: 'actions'},
                ]"
                :hide-default-footer="true"
                :items-per-page="-1"
                @dblclick:row="selectedTime"
              >
                <template
                  v-slot:item.name="{item, index}"
                >
                  <span class="d-block" v-for="event in eventsInTime(item, horarios[index + 1])" :key="event.VueAgendaEvento.id">
                    <span v-if="!event.VueAgendaEvento.canceled_date">{{event.VueAgendaEvento.inicio_evento.substring(11, 16)}} - {{event.VueAgendaEvento.name}}</span>
                  </span>
                </template>
                <template
                  v-slot:item.actions="{item, index}"
                >
                  <v-icon v-if="!eventsInTime(item, horarios[index + 1]).filter(event => !event.VueAgendaEvento.canceled_date).length > 0" @click="selectedTime(item, horarios[index + 1])">mdi-plus</v-icon>
                  <v-icon @click="openEditEventDialog(item, horarios[index + 1])">mdi-pencil</v-icon>
                  <v-icon @click="confirmCancelEvent(item, horarios[index + 1])">mdi-cancel</v-icon>
                </template>
              </v-data-table>
              <v-btn>Próxima cita</v-btn>
            </v-sheet>
          </v-tab-item>
          <v-tab-item>
            <v-sheet
              height=500px
              style="overflow: auto"
            >
              <v-data-table :items="horarios" :headers="[
                {text: 'Hora', value: 'time'},
                {text: 'Evento', value: 'name'},
                ]"
                :hide-default-footer="true"
                :items-per-page="-1"
              >
                <template
                  v-slot:item.name="{item, index}"
                >
                  <span class="d-block" v-for="event in eventsInTime(item, horarios[index + 1])" :key="event.VueAgendaEvento.id">
                    <span v-if="event.VueAgendaEvento.canceled_date">{{event.VueAgendaEvento.inicio_evento.substring(11, 16)}} - {{event.VueAgendaEvento.name}}</span>
                  </span>
                </template>
              </v-data-table>
              <v-btn>Próxima cita</v-btn>
            </v-sheet>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <confirm-dialog v-model="cancelConfirmationDialog" @onCanceled="cancelEvent" :title="'Cancelación de cita'" :text="'¿Estás seguro que deseas cancelar esta cita?'"></confirm-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  mounted () {
    this.$store.dispatch('Patient/index');
    this.$store.dispatch('Usuario/index');
    this.$store.dispatch('VueAgendaEvento/index');
    this.personalSearch = this.personal
    this.$refs.calendar.scrollToTime('08:00')
  },
  watch : {
    personsDirectoryNameSearch () {
      if (this.personsDirectoryNameSearch?.length > 0)
        this.directoryPersonsSearch(this.personsDirectoryNameSearch, 'name')
    },
    personsDirectoryPhoneSearch () {
      if (this.personsDirectoryPhoneSearch?.length > 0)
        this.directoryPersonsSearch(this.personsDirectoryPhoneSearch, 'phone')
    },
    personsDirectoryEmailSearch () {
      if (this.personsDirectoryEmailSearch?.length > 0)
        this.directoryPersonsSearch(this.personsDirectoryEmailSearch, 'email')
    },
    appointmentDialog () {
      if (!this.appointmentDialog) {
        this.edittingEventId = null
      }
    },
    createPersonalModal() {
      if (!this.createPersonalModal) {
        this.selectedUserPermission = null
        this.hideUserPersonalTable = false
      }
    },
    daySelected () {
      if (typeof (this.daySelected) != 'object') {
        const dateArray = this.daySelected.split('-')
        this.parsedDate = {
          date: this.daySelected,
          year: dateArray[0],
          month: dateArray[1],
          day: dateArray[2]
        }
      } else {
        this.parsedDate = {
          date: this.daySelected.date,
          year: this.daySelected.year,
          month: this.daySelected.month,
          day: this.daySelected.day
        }
      }
    },
    searchField () {
      if (this.timeout)
        clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$store.dispatch('Patient/index', '?limit=100&name=' + this.searchField);
      }, this.searchDelay);
    }
  },
  methods: {
    directoryPersonsSearch (value, attribute) {
      if(attribute == 'name')
        this.personalSearch = this.personalSearch.length > 0 ? this.personalSearch.filter( person => (person.name.toLowerCase()).includes(value.toLowerCase()) ) : this.personal.filter( person => (person.name.toLowerCase()).includes(value.toLowerCase()) )
      if(attribute == 'phone')
        this.personalSearch = this.personalSearch.length > 0 ? this.personalSearch.filter( person => (person.telephone.toLowerCase()).includes(value.toLowerCase()) ) : this.personal.filter( person => (person.telephone.toLowerCase()).includes(value.toLowerCase()) )
      if(attribute == 'email')
        this.personalSearch = this.personalSearch.length > 0 ? this.personalSearch.filter( person => (person.email.toLowerCase()).includes(value.toLowerCase()) ) : this.personal.filter( person => (person.email.toLowerCase()).includes(value.toLowerCase()) )
    },
    openEditEventDialog (firstTime, lastTime) {
      const events = this.eventsInTime(firstTime, lastTime);
      const lastEvent = events[events.length - 1]
      this.appointmentDialog = true
      this.edittingEventId = lastEvent.VueAgendaEvento.id
      this.selectedTime(firstTime, lastTime)
      this.selectedPatient = {
        name: lastEvent.VueAgendaEvento.nombres,
        last_name: lastEvent.VueAgendaEvento.apellido_paterno,
        second_last_name: lastEvent.VueAgendaEvento.apellido_materno,
        telephone: lastEvent.VueAgendaEvento.telephone,
        email: lastEvent.VueAgendaEvento.email,
      }
    },
    editEvent (firstTime, lastTime) {
      let sendObject = {VueAgendaEvento: {}}
      this.appointmentDialog = false
      sendObject.VueAgendaEvento.id = this.edittingEventId
      sendObject.VueAgendaEvento.usuario_id = this.user.id
      sendObject.VueAgendaEvento.hospital_entidad_id = this.entity.id
      sendObject.VueAgendaEvento.name = `${this.selectedPatient.name} ${this.selectedPatient.last_name} ${this.selectedPatient.second_last_name} (1era vez)`
      sendObject.VueAgendaEvento.nombres = `${this.selectedPatient.name}`
      sendObject.VueAgendaEvento.apellido_paterno = `${this.selectedPatient.last_name}`
      sendObject.VueAgendaEvento.apellido_materno = `${this.selectedPatient.second_last_name}`
      sendObject.VueAgendaEvento.telephone = this.selectedPatient.telephone
      sendObject.VueAgendaEvento.email = this.selectedPatient.email
      if (this.selectedBookPatient?.HosPaciente) {
        sendObject.VueAgendaEvento.hos_paciente_id = this.selectedBookPatient.HosPaciente.id
        sendObject.VueAgendaEvento.name = `Cita con el paciente ${this.selectedBookPatient.HosPaciente.nombre_completo}`
      }
      if (this.selectedPatient.date && this.selectedPatient.time) {
        sendObject.VueAgendaEvento.inicio_evento =  `${this.selectedPatient.date} ${this.selectedPatient.time}`
        sendObject.VueAgendaEvento.fin_evento =  `${this.selectedPatient.date} ${this.selectedPatient.time}`
      }
      this.$store.dispatch('VueAgendaEvento/update', sendObject)
    },
    confirmCancelEvent (firstTime, lastTime) {
      this.cancelEventObject = { firstTime, lastTime }
      this.cancelConfirmationDialog = true
    },
    cancelEvent () {
      const now = new Date();
      const events = this.eventsInTime(this.cancelEventObject.firstTime, this.cancelEventObject.lastTime);
      const lastEvent = events[events.length - 1]
      if (!lastEvent.VueAgendaEvento?.hos_paciente_id) {
        delete lastEvent.VueAgendaEvento['hos_paciente_id'];
      }
      lastEvent.VueAgendaEvento['canceled_date'] = now.toISOString().replace('T', ' ').replace('Z', '')
      this.$store.dispatch('VueAgendaEvento/update', lastEvent)
    },
    selectBookPatient(item) {
      this.selectedBookPatient = item
      console.log(item)
      this.selectedPatient = {
        name: item.HosPaciente.nombres,
        last_name: item.HosPaciente.apellido_paterno,
        second_last_name: item.HosPaciente.apellido_materno,
        telephone: item.HosPaciente.celular,
        email: item.HosPaciente.email,
      }
    },
    bookPatient() {
      let sendObject = {}
      this.appointmentDialog = false
      sendObject.usuario_id = this.user.id
      sendObject.hospital_entidad_id = this.entity.id
      sendObject.name = `${this.selectedPatient.name} ${this.selectedPatient.last_name} ${this.selectedPatient.second_last_name} (1era vez)`
      sendObject.nombres = `${this.selectedPatient.name}`
      sendObject.apellido_paterno = `${this.selectedPatient.last_name}`
      sendObject.apellido_materno = `${this.selectedPatient.second_last_name}`
      sendObject.telephone = this.selectedPatient.telephone
      sendObject.email = this.selectedPatient.email
      sendObject.inicio_evento = `${this.parsedDate.date} ${this.selectedAppointmentTime.time}:00`
      sendObject.fin_evento = `${this.parsedDate.date} ${this.selectedAppointmentTime.time}:00`
      if (this.selectedBookPatient?.HosPaciente) {
        sendObject.hos_paciente_id = this.selectedBookPatient.HosPaciente.id
        sendObject.name = `Cita con el paciente ${this.selectedBookPatient.HosPaciente.nombre_completo}`
      }
      this.$store.dispatch('VueAgendaEvento/create', sendObject)
    },
    eventsInTime(initialTime, nextTime) {
      if (!nextTime)
        return []
      return this.vueAgendaEventos.filter( evento => {
        const initialDate = `${this.parsedDate.date} ${initialTime.time}:00`
        const finishDate = `${this.parsedDate.date} ${nextTime.time}:00`
        const boolThisTime = (`${initialDate}` <= evento.VueAgendaEvento.inicio_evento) && (`${finishDate}` > evento.VueAgendaEvento.inicio_evento)
        return boolThisTime
      });
    },
    deletePermission(ERUP) {
      this.$store.dispatch('EsConfUsuAcceso/remove', ERUP.id).then((res) => {
        this.$store.commit('Usuario/removePermission', ERUP.id)
        alert('Permiso eliminado con éxito')
      })
    },
    defineUserSchedule(user) {
      this.selectedUserPermission = user
      this.hideUserPersonalTable = true
    },
    addUserPermission (user) {
      user = user.Usuario
      const search = this.usersSearchString.replaceAll(' ', '')
      this.$store.dispatch('EsConfUsuAcceso/create', {
        EsREsUsuPermiso: {
          usuario_id: user.id,
          ID_esEntidadSalud: this.entity.id,
          usuario_id_name: user.nombre_completo,
          usuario_id_palabras_clave: search,
          json_schedule: JSON.stringify(this.creatingPermission)
        }
      }).then( (res) => {
        this.$store.commit('Usuario/createPermission', res['EsREsUsuPermiso'])
      })
      this.createPersonalModal = false
    },
    openAddPersonalForm (personalType) {
      this.currentPersonalTypeCreate = personalType
      this.createPersonalModal = true
    },
    entityPersonalItemRowBackground (item) {
      return item.id == this.idSelectedPersonal.id ? 'green lighten-2' : '';
    },
    modifyPermission (entityPermission) {
      this.createPersonalModal = true
      this.hideUserPersonalTable = true
      this.selectedUserPermission = entityPermission
      this.creatingPermission = entityPermission.json_schedule ? JSON.parse(entityPermission.json_schedule) : {
        monday: {startTime: null, endTime: null},
        tuesday: {startTime: null, endTime: null},
        wednesday: {startTime: null, endTime: null},
        thursday: {startTime: null, endTime: null},
        friday: {startTime: null, endTime: null},
        saturday: {startTime: null, endTime: null},
        sunday: {startTime: null, endTime: null}
      }
    },
    selectedEntityPersonal (entityPersonal) {
      this.idSelectedPersonal = entityPersonal
    },
    parseDate (value) {
      this.daySelected = {
        date: value.start.date,
        year: value.start.year,
        month: value.start.month,
        day: value.start.day
      }
    },
    next () {
      this.$refs.calendar.next()
    },
    prev () {
      this.$refs.calendar.prev()
    },
    selectedTime (item, row = null) {
      this.selectedAppointmentTime = row?.item || item
      this.appointmentDialog = true
    },
    dayClicked (value) {
      // this.daySelected = value
      // this.daySelected.monthObj = this.months[this.daySelected.month]
    },
    selectPatient (value) {
      this.selectedPatient.name = value.HosPaciente.nombres
      this.selectedPatient.last_name = value.HosPaciente.apellido_paterno
      this.selectedPatient.second_last_name = value.HosPaciente.apellido_materno
      this.selectedPatient.telephone = value.HosPaciente.celular
      this.selectedPatient.email = value.HosPaciente.email
    }
  },
  computed: {
    ...mapGetters({permissionList: 'Usuario/permissionList'}),
    ...mapGetters({vueAgendaEventos: 'VueAgendaEvento/vueAgendaEventos'}),
    entityPersonal () {
      return {
        doctors: [
          {id: '1', full_name: 'Carlos Manuel Murillo Tostado', specialty: 'Neurocirugía'},
          {id: '2', full_name: 'Dr. Luis Esteban Ruelas Zaragoza', specialty: 'Medicina General'}
        ],
        enfermeras: [
          {id: '3', full_name: 'Enfermera1', specialty: 'Cuidados intensivos'},
        ],
        auxiliary: [
          {id: '4', full_name: 'Auxiliar1', specialty: 'Cuidados intensivos'},
        ],
      }
    },
    user () {
      return this.$store.getters['Usuario/user'] ? this.$store.getters['Usuario/user'].Usuario : []
    },
    users() {
      const users = this.$store.getters['Usuario/all']
      return users
    },
    usersSearchString () {
      return `${this.selectedPersonal.name}${this.selectedPersonal.last_name}${this.selectedPersonal.second_last_name}`.toLowerCase()
    },
    usersForTable() {
      if (!this.usersSearchString) return []
      if (this.usersSearchString.length == 0) return []
      if (!this.users) return []
      const search = this.usersSearchString.replaceAll(' ', '')
      return this.users.filter( element => {
        if (element && element.Usuario && element.Usuario.nombre_completo){
          const fullName = element.Usuario.nombre_completo.replaceAll(' ', '').toLowerCase()
          return fullName.includes(search)
        }
        return false
      })
    },
    userPermissions() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      if (!(entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id)) return []
      const permissions = Object.keys(entity.EsREsUsuPermiso).map((element, key) => {
        const permission = entity.EsREsUsuPermiso[key]
        return permission
      })
      console.log('permissions', permissions)
      return permissions.filter( element => element)
    },
    entity() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      return entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id ? entity.EsEntidadSalud : {name: ""}
    },
    stringMonth () {
      if(this.daySelected.month) {
        const monthName = this.months[parseInt(this.daySelected.month)]
        return monthName
      }
      return ''
    },
    searchField () {
      return `${this.selectedPatient.last_name ? this.selectedPatient.last_name + ' ' : ''}${this.selectedPatient.second_last_name ? this.selectedPatient.second_last_name + ' ' : ''}${this.selectedPatient.name ? this.selectedPatient.name + ' ' : ''}`
    },
    patients () {
      if (this.searchField == undefined || this.searchField.length < 1) {
        return []
      }
      return this.$store.getters['Patient/patients'];
    },
    horarios () {
      let horarios = []
      for (let i = 0; i < 25; i++) {
        let timeString = `${i}`
        const time = `${timeString.padStart(2,'0')}:00`
        if (! (time >= this.settings.startTime) || ! (time <= this.settings.endTime)) {
          continue
        }
        horarios.push({
          name: '',
          time
        })
      }
      return horarios
    }
  },
  data () {
    return {
      tabsCanceled: null,
      personsDirectoryEmailSearch: null,
      personsDirectoryNameSearch: null,
      personsDirectoryPhoneSearch: null,
      edittingEventId: null,
      personalSearch: [],
      cancelEventObject: {
        firstTime: null,
        lastTime: null,
      },
      cancelConfirmationDialog: false,
      selectedAppointmentTime: null,
      selectedBookPatient: null,
      timeOptions: [
        {value:15, text: '15 mins'},
        {value: 20, text:'20 mins'},
        {value: 30, text:'30 mins'},
        {value: 45, text:'45 mins'},
        {value: 60, text:'60 mins'},
      ],
      creatingPermission: {
        monday: {startTime: null, endTime: null},
        tuesday: {startTime: null, endTime: null},
        wednesday: {startTime: null, endTime: null},
        thursday: {startTime: null, endTime: null},
        friday: {startTime: null, endTime: null},
        saturday: {startTime: null, endTime: null},
        sunday: {startTime: null, endTime: null}
      },
      hideUserPersonalTable: false,
      selectedUserPermission: {},
      createPersonalModal: false,
      currentPersonalTypeCreate: null,
      selectedPersonal: {
        name: '',
        last_name: '',
        second_last_name: '',
      },
      personalTabs: null,
      idSelectedPersonal: {id: null},
      isConcentrated: false,
      personal: [
        {name:'Luis Esteban Ruelas Zaragoza', telephone: '3339455534', email: 'luise.ruelasz@gmail.com'}
      ],
      companies: [
        {official_name:'Laboratorio Shering-Plough S.A. de C.V.', name:'Schering-Plough', telephone: '1234567890'}
      ],
      addingPersonal: false,
      addingCompany: false,
      personalCreating: {},
      companyCreating: {},
      directoryTabs: null,
      dialog: {
        value: false
      },
      directoryDialog: false,
      time: null,
      startDateMenu: false,
      endDateMenu: false,
      settings: {
        firstTimeDuration: 60,
        subsecuentDuration: 30,
        startTime: "09:00",
        endTime: "16:00",
      },
      months: {
        1: {
          text: 'Enero',
          abv: 'Ene',
        },
        2: {
          text: 'Febrero',
          abv: 'Feb',
        },
        3: {
          text: 'Marzo',
          abv: 'Mar',
        },
        4: {
          text: 'Abril',
          abv: 'Abr',
        },
        5: {
          text: 'Mayo',
          abv: 'May',
        },
        6: {
          text: 'Junio',
          abv: 'Jun',
        },
        7: {
          text: 'Julio',
          abv: 'Jul',
        },
        8: {
          text: 'Agosto',
          abv: 'Ago',
        },
        9: {
          text: 'Septiembre',
          abv: 'Sep',
        },
        10: {
          text: 'Octubre',
          abv: 'Oct',
        },
        11: {
          text: 'Noviembre',
          abv: 'Nov',
        },
        12: {
          text: 'Diciembre',
          abv: 'Dic',
        },
      },
      parametrizationDialog: null,
      appointmentDialog: null,
      daySelected: "",
      parsedDate: {
        date: new Date().toISOString().split('T')[0],
        year: new Date().toISOString().split('T')[0].split('-')[0],
        month: new Date().toISOString().split('T')[0].split('-')[1],
        day: new Date().toISOString().split('T')[0].split('-')[2],
      },
      selectedPatient: {
        name: null,
        last_name: null,
        second_last_name: null,
        telephone: null,
        email: null,
      },
      name: null,
      last_name: null,
      second_last_name: null,
      today: '2019-01-08',
      events: [
        {
          name: 'Weekly Meeting',
          start: '2021-01-07 09:00',
          end: '2021-01-07 10:00',
        },
        {
          name: `Thomas' Birthday`,
          start: '2021-01-10',
        },
        {
          name: 'Mash Potatoes',
          start: '2021-01-09 12:30',
          end: '2021-01-09 15:30',
        },
      ],
    }
  }
}
</script>
<style scoped>
.v-text-field__slot {
  height: 50px;
}

</style>