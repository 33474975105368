<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>CREAR NUEVO PACIENTE</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field outlined v-model="patient.curp" label="CURP"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined v-model="patient.name" label="Nombre(s)"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined label="Apellido Paterno"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined label="Apellido Materno"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select label="Género" outlined :items="['Masculino', 'Femenino']"></v-select>
      </v-col>
      <v-col>
        <v-menu
          ref="menu"
          v-model="birth_date_menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="patient.birth_date"
              label="Fecha de nacimiento"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="patient.birth_date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select outlined label="Estado de nacimiento" :items="['Aguascalientes']"></v-select>
      </v-col>
      <v-col>
        <v-text-field outlined label="Tel. Contacto"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field outlined label="Tel. Casa"></v-text-field>
      </v-col>
      <v-col cols=12>
        <v-btn class="green lighten-2" @click="createPatient" dark><v-icon>mdi-cloud</v-icon> Buscar en la Nube</v-btn>
      </v-col>
    </v-row>
    <br>
    <hr>
    <br>
    <v-row v-if="fillPatient">
      <v-col cols=12>
        <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <h3>Foto del paciente</h3>
                  </v-col>
                </v-row>
                <input type="file">
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>Lugar de nacimiento</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-select outlined :items="['México']" label="País"></v-select>
                  </v-col>
                  <v-col>
                    <v-select outlined :items="['Jalisco']" label="Estado"></v-select>
                  </v-col>
                  <v-col>
                    <v-select outlined :items="['Municipio']" label="Municipio"></v-select>
                  </v-col>
                  <v-col>
                    <v-select outlined :items="['Guadalajara']" label="Ciudad"></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>Domicilio</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-select outlined :items="['México']" label="País"></v-select>
                  </v-col>
                  <v-col>
                    <v-select outlined :items="['Jalisco']" label="Estado"></v-select>
                  </v-col>
                  <v-col>
                    <v-select outlined :items="['Municipio']" label="Municipio"></v-select>
                  </v-col>
                  <v-col>
                    <v-select outlined :items="['Guadalajara']" label="Ciudad"></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field outlined label="Colonia"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined label="CP"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined label="Calle"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined label="#Ext"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field outlined label="#Int"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Datos de contacto de familiares del paciente</h2>
                <v-btn class="green lighten-2" dark @click="()=>{contacts.push({})}">Añadir contacto</v-btn>
              <v-col>
              </v-col>
                <v-row>
                  <v-col lg=3 v-for="contact in contacts" :key="contact.id">
                      <v-select outlined label="Parentesco" :items="['Abuelos', 'Cónyuge', 'Familiar', 'Hermano', 'Hijo', 'Madre', 'Padre', 'Tío', 'Representante', 'Sobrino', 'Primo', 'Tutor']">Contacto con familiar</v-select>
                      <v-text-field outlined label="Nombre"></v-text-field>
                      <v-text-field outlined label="Email"></v-text-field>
                      <v-text-field outlined label="Tel Celular"></v-text-field>
                      <v-text-field outlined label="Tel Casa"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols=12>
                <h2>Seguridad Social</h2>
                <v-btn class="green lighten-2" dark @click="()=>{socialSecurities.push({})}">Añadir Seguridad Social</v-btn>
              </v-col>
              <v-col lg=3 v-for="socialSecurity in socialSecurities" :key="socialSecurity.id">
                <v-row>
                  <v-col>
                    <v-select label="Institución" outlined :items="['IMSS', 'ISSSTE', 'ISSSTE ESTATAL', 'OTRO']"></v-select>
                    <v-text-field label="Nómero" outlined></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols=12>
                <h2>Aseguradoras</h2>
                <v-btn class="green lighten-2" dark @click="()=>{insurances.push({})}">Añadir Aseguradora</v-btn>
              </v-col>
              <v-col lg=3 v-for="insurance in insurances" :key="insurance.id">
                <v-row>
                  <v-col>
                    <v-select label="Aseguradora" outlined :items="['ABA Seguros']"></v-select>
                    <v-text-field label="Nómero" outlined></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn class="green lighten-2" dark>Añadir Paciente</v-btn>
              </v-col>
            </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  methods: {
    createPatient () {
      this.$store.dispatch('Patient/create')
      this.fillPatient = true
    }
  },
  data() {
    return {
      fillPatient: null,
      contacts: [],
      insurances: [],
      socialSecurities: [],
      birth_date_menu: false,
      patient: {
        curp:null,
        birth_date:null
      }
    }
  },
}
</script>

<style lang="scss">
</style>
