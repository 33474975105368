import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProtocolsForm from '../components/protocols/Form.vue'
import ClinicalEntriesFreeCreate from '../components/clinical_entries/free/Create.vue'
import ClinicalEntriesProtocolizedCreate from '../components/clinical_entries/protocolized/Create.vue'
import PatientIndex from '../components/patients/Index.vue'
import PatientCreate from '../components/patients/Create.vue'
import DoctorsDirecotryIndex from '../components/doctors_directory/Index.vue'
import Login from '../components/home/Index.vue'
import DoctorCreate from '../components/users/doctors/Create.vue'
import LibCatCie10Index from '../components/libraries/CatCie10Library.vue'
import LibCatCPTIndex from '../components/libraries/CatCPTLibrary.vue'
import LibCatMedicamentsIndex from '../components/libraries/VademecumMedicaments.vue'
import LibCatLaboratoriesIndex from '../components/libraries/VademecumLaboratories.vue'
import AgendaIndex from '../components/agenda/Index.vue'
import EncounterList from '../components/patients/EncounterList.vue'
import EncounterIndex from '../components/patients/HospitalizationNotes.vue'
import HealthStablishmentConfiguration from '../components/health_stablishment/configuration.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/encounter',
    name: 'EncounterIndex',
    component: EncounterIndex
  },
  {
    path: '/encounter_list',
    name: 'EncounterList',
    component: EncounterList
  },
  {
    path: '/index',
    name: 'Login',
    component: Login
  },
  {
    path: '/agenda',
    name: 'AgendaIndex',
    component: AgendaIndex
  },
  {
    path: '/users/doctors/create',
    name: 'DoctorCreate',
    component: DoctorCreate
  },
  {
    path: '/mis_medicos',
    name: 'DoctorsDirecotryIndex',
    component: DoctorsDirecotryIndex
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  //CRUD PACIENTES
  {
    path: '/patients',
    name: 'PatientIndex',
    component: PatientIndex
  },
  {
    path: '/patients/create',
    name: 'PatientCreate',
    component: PatientCreate
  },
  //CRUD Protocolos
  {
    path: '/protocols/create',
    name: 'ProtocolsCreate',
    component: ProtocolsForm
  },
  {
    path: '/protocols/:protocolId/edit',
    name: 'ProtocolsEdit',
    component: ProtocolsForm
  },
  //CRUD Clinical Entries free
  {
    path: '/clinical_entries_free/:patientId/create',
    name: 'ClinicalEntriesFreeCreate',
    component: ClinicalEntriesFreeCreate
  },
  //Clinical Stablishment configuration 
  {
    path: '/health_stablishment/configuration',
    name: 'HealthStablishmentConfiguration',
    component: HealthStablishmentConfiguration
  },
  //CRUD Clinical Entries protocolized
  {
    path: '/clinical_entries_protocolized/:patientId/:protocolId/create',
    name: 'ClinicalEntriesProtocolizedCreate',
    component: ClinicalEntriesProtocolizedCreate
  },
  {
    path: '/librerias/cat_cie10/',
    name: 'LibCatCie10Index',
    component: LibCatCie10Index
  },
  {
    path: '/librerias/cat_cpt/',
    name: 'LibCatCPTIndex',
    component: LibCatCPTIndex
  },
  {
    path: '/librerias/cat_vademecum_medicaments/',
    name: 'LibCatMedicamentsIndex',
    component: LibCatMedicamentsIndex
  },
  {
    path: '/librerias/cat_vademecum_laboratories/',
    name: 'LibCatLaboratoriesIndex',
    component: LibCatLaboratoriesIndex
  },
]

const router = new VueRouter({
  routes
})

export default router
