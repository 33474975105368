<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
    <v-card>
      <v-card-title class="text-h5">
        {{title}}
      </v-card-title>
      <v-card-text>{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="returnAnswer(false)"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="returnAnswer"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  methods: {
    returnAnswer (answer = true) {
      this.dialog = false
      if (answer)
        this.$emit('onCanceled', answer)
      this.$emit('input', this.dialog)
    }
  },
  data() {
    return {dialog: false}
  },
  watch: {
    value () {
      this.dialog = this.value
    }
  },
  props: ['value', 'text', 'title']
}
</script>