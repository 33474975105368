
import axios from 'axios'
import ENV from '../../../env'
export default {
  namespaced: true,
  state: {
    patientList: [],
  },
  mutations: {
    patientList (state, payload) {
      state.patientList = payload
    },
  },
  actions: {
    index ({commit, state}, payload = '') {
      return axios.get('https://navegacion.exinnotch.com/api/patients'+payload).then((res) => {
        commit('patientList', res.data)
        return res.data
      });
    },
    create ({commit, state}, payload) {
      return axios.post(ENV.patientCreateUrl, payload).then((res) => {
        commit('patientList', res.data)
        return res.data
      });
    },
  },
  getters: {
    patients (state) {
      return state.patientList
    },
  }
}