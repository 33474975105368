import axios from 'axios'
export default {
  namespaced: true,
  state: {
    modelName: 'Cie10',
    cie10s: [],
    cie10codes: [],
    escalones: {
      signs: [],
      symptoms: [],
      diagnosis: [],
    },
    libCie10s: [],
    libCpts: [],
    medicaments: [],
    laboratories: [],
  },
  mutations: {
    libCie10s (state, payload) {
      state.libCie10s = payload
    },
    libCpts (state, payload) {
      state.libCpts = payload
    },
    cie10s (state, payload) {
      state.cie10s = payload
    },
    cie10codes (state, payload) {
      state.cie10codes = payload
    },
    protocol (state, payload) {
      state.protocol = payload
    },
    escalones (state, payload) {
      state.escalones = payload
    },
    medicaments (state, payload) {
      state.medicaments = payload
    },
    laboratories (state, payload) {
      state.laboratories = payload
    }
  },
  actions: {
    libMedicaments ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/ApiLibVademecum/medicaments').then((res) => {
        commit('medicaments', res.data)
        return res.data
      });
    },
    libLaboratories ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/ApiLibVademecum/laboratories').then((res) => {
        commit('laboratories', res.data)
        return res.data
      });
    },
    libCie10s ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/ApiCatLibCie10').then((res) => {
        commit('libCie10s', res.data)
        return res.data
      });
    },
    libCpts ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/ApiCatLibCPT').then((res) => {
        commit('libCpts', res.data)
        return res.data
      });
    },
    selectTabla ({commit, state}, payload) {
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return axios.post('https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla', payload, config).then((res) => {
        commit('cie10s', res.data)
        return res.data
      });
    },
    levelCie10 ({commit, state}, payload) {
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return axios.get(`https://navegacion.exinnotch.com/api/Cie10?${payload}`,config).then((res) => {
        commit('cie10codes', res.data)
        return res.data
      });
    },
    create ({commit, state}, payload) {
      return axios.post('https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla', payload).then((res) => {
        commit('protocol', res.data)
        return res.data
      });
    },
    protocol ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla/'+payload).then((res) => {
        commit('protocol', res.data)
      });
    },
    escalones ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/escalones_protocolos/'+payload).then((res) => {
        commit('escalones', res.data)
      });
    },
    edit ({commit, state}, payload) {
      return axios.put(`https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla/${payload.id}/edit`, payload.model).then((res) => {
        commit('protocol', res.data)
      });
    }
  },
  getters: {
    cie10s (state) { return state.cie10s},
    cie10codes (state) { return state.cie10codes},
    protocol (state) { return state.protocol},
    escalones (state) { return state.escalones},
    libCie10s (state) { return state.libCie10s},
    libCpts (state) { return state.libCpts},
    medicaments (state) { return state.medicaments},
    laboratories (state) { return state.laboratories},
  }
}