<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>MI DIRECORIO DE MÉDICOS</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="green lighten-2" dark><v-icon>mdi-plus</v-icon> Añadir</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="[
            {text: 'Nombre', value: 'nombre_completo'},
            {text: 'Especialidad', value: 'especialidad'},
          ]"
          :items= "[
            {nombre_completo: 'Luis Ruelas', especialidad: 'Médico General'}
          ]"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>