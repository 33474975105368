<template>
  <div id="app">
    <v-app>
      <div class="">
        <v-card>
          <v-navigation-drawer
            permanent
            expand-on-hover
            app
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">
                  Application
                </v-list-item-title>
                <v-list-item-subtitle>
                  subtext
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
              dense
              nav
            >
              <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            </v-navigation-drawer>
        </v-card>
      </div>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'Inicio',
          icon: 'mdi-home',
          to: {name: 'Home'}
        },
        {
          title: 'Agenda',
          icon: 'mdi-home',
          to: {name: 'AgendaIndex'}
        },
        {
          title: 'Protocolos',
          icon: 'mdi-home',
          to: {name: 'ProtocolsCreate'}
        },
        {
          title: 'Mis Médicos',
          icon: 'mdi-account-details',
          to: {name: 'DoctorsDirecotryIndex'}
        },
        {
          title: 'CIE10-PCS',
          tooltip: 'Procedural clasification system',
          icon: 'mdi-book',
          to: {name: 'LibCatCie10Index'}
        },
        {
          title: 'CIE10-CM',
          tooltip: 'Clinico modificado',
          icon: 'mdi-book',
          to: {name: 'LibCatCie10Index'}
        },
        {
          title: 'CIE9',
          tooltip: 'Clasificación internacional de enfermedades',
          icon: 'mdi-book',
          to: {name: 'LibCatCie10Index'}
        },
        {
          title: 'Diccionario Médico',
          tooltip: 'Clasificación internacional de enfermedades',
          icon: 'mdi-book',
          to: {name: 'LibCatCie10Index'}
        },
        {
          title: 'CPT',
          icon: 'mdi-book',
          to: {name: 'LibCatCPTIndex'}
        },
        {
          title: 'Vademecum',
          icon: 'mdi-book',
          to: {name: 'LibCatMedicamentsIndex'}
        },
        {
          title: 'Laboratorios',
          icon: 'mdi-book',
          to: {name: 'LibCatLaboratoriesIndex'}
        },
        {
          title: 'Estadística',
          icon: 'mdi-book',
          to: {name: 'LibCatLaboratoriesIndex'}
        },
        {
          title: 'Simulador',
          icon: 'mdi-book',
          to: {name: 'LibCatLaboratoriesIndex'}
        },
      ]
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
