<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>LABORATORIOS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="laboratories" :headers="headers"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    console.log('library')
    this.$store.dispatch('Cie10/libLaboratories')
  },
  computed: {
    laboratories () {
      return this.$store.getters['Cie10/laboratories']
    }
  },
  data () {
    return {
      headers: [
        {text: 'Laboratorio', value: 'AppVadMedCatLaboratorio.name'},
        {text: '# de Medicamentos', value: 'AppVadMedCatLaboratorio.numero_medicamentos'},
      ]
    }
  }
}
</script>

<style>

</style>