<template>
  <v-container>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1000"
      v-model="documentsDialog"
      >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
            color="blue lighten-2"
            dark
          > IMPRIMIR DOCUMENTOS
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols=12 class="text-left"><a href="">Receta(s?)</a></v-col>
                <v-col cols=12 class="text-left"><a href="">Constancia de asistenacia a consulta</a></v-col>
                <v-col cols=12 class="text-left"><a href="">Constancia de hospitalización</a></v-col>
                <v-col cols=12 class="text-left"><a href="">Carta de recomendación</a></v-col>
                <v-col cols=12 class="text-left"><a href="">Carta de agradecimiento al referidor</a></v-col>
                <v-col cols=12 class="text-left"><a href="">Informe clínico</a></v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
                <v-btn
                  @click="dialog.value = false"
                >Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row>
      <v-col>
        <h2>Lista de Pacientes</h2>
        <h4>{{entity.name}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :to="{name:'PatientCreate'}" class="green lighten-2" dark>Crear nuevo paciente</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="searchField" outlined placeholder="Buscar por Nombre"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :items="patients"
          :headers = "patientHeaders"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="documentsDialog = true"
            >
              mdi-file-document
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-arrow-right-thick
            </v-icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="goEncounterList(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-stethoscope</v-icon>
              </template>
              <span>Ver lista de encuentros</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted () {
  },
  methods: {
    goToNote () {
      this.$router.push({name: 'ClinicalEntriesFreeCreate'})
    },
    goEncounterList(item) {
      this.$router.push({name: 'EncounterList'})
    },
  },
  computed : {
    entity() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      return entity && entity.id ? entity : {name: ""}
    },
    patients () {
      return this.$store.getters['Patient/patients'];
    }
  },
  watch : {
    searchField () {
      if (this.timeout)
        clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$store.dispatch('Patient/index', '?limit=100&name=' + this.searchField);
      }, this.searchDelay);
    }
  },
  data () {
    return {
      documentsDialog: false,
      searchField: '',
      searchTimeout : null,
      searchDelay : 500,
      patientHeaders: [
        {text: 'Nombre', value: 'HosPaciente.nombre_completo'},
        {text: 'Acciones', value: 'actions'},
      ]
    }
  }
}
</script>

<style>

</style>