import Vue from 'vue'
import Vuex from 'vuex'
import ProtocolAPI from './models/protocol.ts'
import Cie10 from './models/cie10.ts'
import Complication from './models/complication.ts'
import Patient from './models/patient.ts'
import Cie10CPS from './models/cie10cps.ts'
import Usuario from './models/usuario.ts'
import ClinicalNote from './models/clinical_note.ts'
import EsConfUsuAcceso from './models/es_conf_usu_acceso.ts'
import VueAgendaEvento from './models/vue_agenda_evento.ts'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Usuario,
    ProtocolAPI,
    Cie10,
    Complication,
    Patient,
    Cie10CPS,
    ClinicalNote,
    EsConfUsuAcceso,
    VueAgendaEvento
  }
})
