<template>
  <v-container>
    <v-form ref="form">
      <v-row v-if="false">
        <v-col>
          <v-text-field v-model="curp" label="CURP" outlined></v-text-field>
          <v-btn class="green lighten-2" dark @click="validateCurp">VALIDAR CURP</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>CREAR UN USUARIO</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>DATOS DEL PROFESIONAL</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field v-model="Usuario.cedula_profesional" outlined label="Cédula profesional" :rules="rules.mandatory"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="Usuario.universidad" disabled outlined label="Universidad"></v-text-field>
            </v-col>
          </v-row>
          <h3 v-if="apiCedulaProfesional">Cédula Profesional</h3>
          <v-text-field outlined v-if="apiCedulaProfesional" disabled v-model="apiCedulaProfesional.titulo" label="Título"></v-text-field>
          <v-btn class="green lighten-2 mb-5" dark @click="validateCedulaProfesional(Usuario.cedula_profesional)">Validar cédula</v-btn>
          <v-row>
            <v-col>
              <v-text-field v-model="Usuario.cedulaEspecialidad" outlined label="Cédulas de especialidad"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="Usuario.institutionSpeciality" outlined label="Institución Educativa"></v-text-field>
            </v-col>
          </v-row>
          <v-btn class="green lighten-2" @click="addSpecialistLicence(Usuario.cedulaEspecialidad)" dark>Añadir cédula de especialista</v-btn>
          <br>
          <h3 v-if="arrayAPICedulasEspecialidad.length > 0">Cédulas de especialista</h3>
          <v-row v-for="cedula in arrayAPICedulasEspecialidad" :key="cedula.idCedula">
            <v-col>
              <v-text-field outlined disabled :value="cedula.titulo" label="Título"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field outlined disabled :value="cedula.institution" label="Institución"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="cedula.desins" disabled outlined label="Universidad"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field outlined disabled :value="`${cedula.nombre} ${cedula.paterno} ${cedula.materno}`" label="Nombre"></v-text-field>
            </v-col >
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>DATOS PERSONALES</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :rules="rules.mandatory" :disabled="Usuario.cedula ? true: false" v-model="Usuario.nombres" outlined label="Nombre(s)"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :rules="rules.mandatory" :disabled="Usuario.cedula ? true: false" v-model="Usuario.apellido_paterno" outlined label="Apellido paterno"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :rules="rules.mandatory" :disabled="Usuario.cedula ? true: false" outlined v-model="Usuario.apellido_materno" label="Apellido materno"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
          outlined
          :rules="rules.mandatory"
          v-model="Usuario.genero"
          item-text="name"
          item-value="id"
          label="Sexo"
          :items="[{id:'H', name:'Masculino'}, {id:'M', name: 'Femenino'}]">
          </v-select>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-menu
            ref="menu"
            v-model="menu_fecha_de_nacimiento"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="Usuario.fecha_nacimiento"
                label="Fecha de nacimiento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-select
          outlined
          label="Estado de nacimiento"
          :items="['Aguascalientes']"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>DATOS DE CONTACTO </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :rules="rules.mandatory" outlined v-model="Usuario.email" label="Correo electrónico"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="Usuario.celular" label="Teléfono celular"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="Usuario.tel_casa" label="Tel. casa"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field outlined v-model="Usuario.tel_trabajo" label="Tel. trabajo"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>AUTENTIFICACIÓN </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :rules="rules.mandatory" outlined v-model="Usuario.name" label="Usuario"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :rules="rules.mandatory" outlined v-model="Usuario.password" type="password" label="Contraseña"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :rules="rules.mandatory" outlined v-model="Usuario.password_repetido" type="password" label="Confirme contraseña"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox label="He leído y acepto los Términos y condiciones de uso" v-model="Usuario.terminos_condiciones"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="green lighten-2" dark @click="addUser">Agregar Usuario</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  methods: {
    addUser () {
      if (this.$refs.form.validate()) {
        const Usuario = this.Usuario
        this.$store.dispatch('Usuario/create', {Usuario}).then (res => {
            alert(res.data)
            this.$router.push({name: 'Home'})
        })
      } else {
        this.$el.querySelector(".v-messages.theme--light").scrollIntoView({ behavior: 'smooth' })
      }
    },
    addSpecialistLicence(cedula) {
      this.$store.dispatch('Usuario/validateCedula',cedula).then(res => {
        let cedulaEspecialidad = res.items.filter(apiCedula => apiCedula.idCedula.includes(cedula) && apiCedula.tipo == 'A1')
        console.log(cedulaEspecialidad)
        if(cedulaEspecialidad.length > 0) {
          console.log('cedulaEspecialidad[0]', cedulaEspecialidad[0])
          const cedulaEspecialidadObject = cedulaEspecialidad[0]
          cedulaEspecialidadObject.institution = this.Usuario.institutionSpeciality
          this.arrayAPICedulasEspecialidad.push(cedulaEspecialidadObject)
        } else {
          alert('La cédula de especialidad no existe')
        }
      })
    },
    validateCedulaProfesional(cedula) {
      this.$store.dispatch('Usuario/validateCedula',cedula).then(res => {
        console.log(res)
        this.apiCedulaProfesional = res.items.filter(apiCedula => {
          return apiCedula.tipo == 'C1'
        })
        if (this.apiCedulaProfesional.length > 0) {
          this.apiCedulaProfesional = this.apiCedulaProfesional[0]
          this.Usuario.universidad = this.apiCedulaProfesional.desins
          this.Usuario.sexo = parseInt(this.apiCedulaProfesional.sexo)
          this.Usuario.name = this.apiCedulaProfesional.nombre
          this.Usuario.last_name = this.apiCedulaProfesional.paterno
          this.Usuario.second_last_name = this.apiCedulaProfesional.materno
        } else {
          alert('La cédula profesional no existe')
        }
      })
    },
    validateCurp(cedula) {
      this.$store.dispatch('Usuario/validateCurp', this.curp).then(res => {
          this.Usuario.name = res.names
          this.Usuario.last_name = res.nationality
          this.Usuario.second_last_name = res.paternal_surname
          const arrayBirthDate = res.birthdate.split("/");
          this.Usuario.fecha_nacimiento = `${arrayBirthDate[2]}-${arrayBirthDate[1]}-${arrayBirthDate[0]}`
      })
    }
  },
  data () {
    return {
      curp: null,
      date:null,
      menu_fecha_de_nacimiento: null,
      apiCedulaProfesional: null,
      cedulaEspecialidad: null,
      institutionSpeciality: null,
      arrayAPICedulasEspecialidad: [],
      rules: {
        mandatory: [
          v => (v || '').length > 0 || "Debes llenar este campo"
        ]
      },
      Usuario: {
        nombres: null,
        email: null,
        genero: null,
        celular: null,
        tel_casa: null,
        tel_trabajo: null,
        apellido_paterno: null,
        apellido_materno: null,
        cedula_profesional: null,
        universidad: null,
        fecha_nacimiento: null,
        name: null,
        password: null,
        password_repetido: null,
        terminos_condiciones: 0
      }
    }
  }

}
</script>

<style>

</style>