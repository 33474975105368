<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="1000"
    v-model="showDialog"
    >
    <template v-slot:default="dialog">
    <v-card>
      <v-toolbar
          color="blue lighten-2"
          dark
        > AGREGAR PERSONAL {{selectedPermissionType}}
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-btn class="lighten-2" @click="selectedPermissionType=': MÉDICO'">Médico</v-btn>
            </v-col>
            <v-col>
              <v-btn class="pink lighten-2" @click="selectedPermissionType=': ENFERMERÍA'" dark>ENFERMERÍA</v-btn>
            </v-col>
            <v-col>
              <v-btn class="blue lighten-2" @click="selectedPermissionType=': AUXILIAR'" dark>AUXILIAR</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=4><v-text-field v-model="selectedPersonal.name" outlined label="Nombre"></v-text-field></v-col>
            <v-col cols=4><v-text-field v-model="selectedPersonal.last_name" outlined label="Apellido paterno"></v-text-field></v-col>
            <v-col cols=4><v-text-field v-model="selectedPersonal.second_last_name" outlined label="Apellido materno"></v-text-field></v-col>
            <v-col cols=12 v-if="!hideUserPersonalTable">
              <v-data-table
                :items="usersForTable"
                :headers="[
                  {text: 'Nombre', value: 'Usuario.nombre_completo'}
                ]"
                @click:row="defineUserSchedule"
              >
              </v-data-table>
            </v-col>
            <v-col v-else>
              <h3>Horario de atención para el usuario</h3>
              <h3>{{selectedUserPermission ? selectedUserPermission.Usuario.nombre_completo : ''}}</h3>
              <v-row>
                <v-col cols=6>
                  <h4 class="mx auto mt-5">Duración primera vez</h4>
                  <v-select v-model="creatingPermission.first_time_mins" outlined :items="timeOptions" item-value="value" item-text="text"/>                
                </v-col>
                <v-col cols=6>
                  <h4 class="mx auto mt-5">Duración consulta subsecuente</h4>
                  <v-select v-model="creatingPermission.subsecuent_time_mins" outlined :items="timeOptions" item-value="value" item-text="text"/>  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Lunes</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.monday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.monday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Martes</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.tuesday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.tuesday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Miercoles</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.wednesday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.wednesday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Jueves</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.thursday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.thursday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Viernes</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.friday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.friday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Sabado</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.saturday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.saturday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols=3>
                  <h4 class="mx auto mt-5">Domingo</h4>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.sunday.startTime" outlined label="Hora de inicio"></v-text-field>
                </v-col>
                <v-col cols=4>
                  <v-text-field v-model="creatingPermission.sunday.endTime" outlined label="Hora de fin"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn class="green lighten-2" dark @click="addUserPermission(selectedUserPermission)">Añadir permiso</v-btn>
                </v-col>
              </v-row>
              
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
            <v-btn
              @click="dialog.value = false"
            >Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    </template>
  </v-dialog>  
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('Usuario/index');
  },
  computed: {
    users() {
      const users = this.$store.getters['Usuario/all']
      return users
    },
    entity() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      return entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id ? entity.EsEntidadSalud : {name: ""}
    },
    usersSearchString () {
      return `${this.selectedPersonal.name}${this.selectedPersonal.last_name ? this.selectedPersonal.last_name:''}${this.selectedPersonal.second_last_name?this.selectedPersonal.second_last_name:''}`.toLowerCase()
    },
    usersForTable() {
      if (!this.usersSearchString) return []
      if (this.usersSearchString.length == 0) return []
      if (!this.users) return []
      const search = this.usersSearchString.replaceAll(' ', '')
      return this.users.filter( element => {
        if (element && element.Usuario && element.Usuario.nombre_completo){
          const fullName = element.Usuario.nombre_completo.replaceAll(' ', '').toLowerCase()
          return fullName.includes(search)
        }
        return false
      })
    },
  },
  watch: {
    showDialog () {
      this.$emit('input', this.showDialog)
    },
    value () {
      this.showDialog = this.value
    }
  },
  methods: {
    defineUserSchedule(user) {
      this.selectedUserPermission = user
      this.hideUserPersonalTable = true
    },
    deletePermission(ERUP) {
      this.$store.dispatch('EsConfUsuAcceso/remove', ERUP.id).then((res) => {
        this.$store.commit('Usuario/removePermission', ERUP.id)
        alert('Permiso eliminado con éxito')
      })
    },
    addUserPermission (user) {
      user = user.Usuario
      const search = this.usersSearchString.replaceAll(' ', '')
      this.$store.dispatch('EsConfUsuAcceso/create', {
        EsREsUsuPermiso: {
          usuario_id: user.id,
          ID_esEntidadSalud: this.entity.id,
          usuario_id_name: user.nombre_completo,
          usuario_id_palabras_clave: search,
          json_schedule: JSON.stringify(this.creatingPermission)
        }
      }).then( (res) => {
        this.$store.commit('Usuario/createPermission', res['EsREsUsuPermiso'])
      })
      this.createPersonalModal = false
    },
  },
  data() {
    return {
      timeOptions: [
        {value:15, text: '15 mins'},
        {value: 20, text:'20 mins'},
        {value: 30, text:'30 mins'},
        {value: 45, text:'45 mins'},
        {value: 60, text:'60 mins'},
      ],
      selectedPermissionType: '',
      hideUserPersonalTable: false,
      showDialog: false,
      selectedPersonal: {},
      selectedUserPermission: null,
      creatingPermission: {
        monday: {startTime: null, endTime: null},
        tuesday: {startTime: null, endTime: null},
        wednesday: {startTime: null, endTime: null},
        thursday: {startTime: null, endTime: null},
        friday: {startTime: null, endTime: null},
        saturday: {startTime: null, endTime: null},
        sunday: {startTime: null, endTime: null}
      },
    }
  },
  props: ['value']
}
</script>

<style>

</style>