var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Lista de encuentros")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"green lighten-2",attrs:{"dark":""},on:{"click":function($event){return _vm.goToNote()}}},[_vm._v("Agregar nueva nota")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Mis encuentros ")]),_c('v-tab',[_vm._v(" Otros ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('h4',[_vm._v(_vm._s(_vm.entity.name))]),_c('v-data-table',{attrs:{"items":_vm.thisStablishmentEncounters,"headers":_vm.headers,"sort-by":['date'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToNote(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-stethoscope")])]}}],null,true)},[_c('span',[_vm._v("Ver nota")])])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"items":_vm.otherStablishmentsEncounters,"headers":_vm.headers,"sort-by":['date'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.type==1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToNote(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-stethoscope")])]}}],null,true)},[_c('span',[_vm._v("Ver nota")])]):_vm._e(),(item.type==3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.type==3)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goToEncounter(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-arrow-right")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ver encuentro")])]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }