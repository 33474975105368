<template>
  <v-expansion-panels>
    <v-expansion-panel @change="expand">
      <v-expansion-panel-header>
        <v-tooltip v-if="step.AppEscalonesCie10Cps.explicacion" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on">
              mdi-magnify
            </v-icon>
          </template>
          <span>{{step.AppEscalonesCie10Cps.explicacion}}</span>
        </v-tooltip>
        <strong>({{step.AppEscalonesCie10Cps.clave}}) {{step.Child.name}}</strong>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <LCERecursive :complication="complication" @click='itemSelected' @itemSelected="itemSelected" :baseId="baseId" :rules="rules" :startStringAtDepth="startStringAtDepth" :depth="depth+1" @onNameSelected="onNameSelected" v-for="step in selectedChild.escalones" :key="step.Child.id" :step="step"></LCERecursive>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "LCERecursive",
  mounted () {
  },
  methods: {
    itemSelected () {
      console.log('itemSelected', this.depth)
      if (this.depth == 7) {
        this.$emit('itemSelected')
      }
    },
    _getSteps (escalon) {
      const parsingDepth = this.depth + 1
      if (escalon.escalones && escalon.escalones.length > 0) {
        this.rules.forEach(rule => {
          if (rule.exclude && rule.exclude.length > 0){
            console.log('Erule.base', rule.base)
            console.log('Ethis.baseId', this.baseId)
            console.log('EparsingDepth', parsingDepth)
            console.log('Erule.position', rule.position)
            console.log('Ethis.baseId == rule.base', this.baseId == rule.base)
            console.log('EparsingDepth == rule.position', parsingDepth == rule.position)

            if (this.baseId == rule.base && parsingDepth == rule.position) {
              const excludeFilter = escalon.escalones.filter(step => {
                return !rule.exclude.includes(step.AppEscalonesCie10Cps.clave)
              })
              console.log('excludeFilter', excludeFilter)
              escalon.escalones = excludeFilter.length > 0 ? excludeFilter : escalon.escalones
            }
          }
          if (rule.include && rule.include.length > 0){
            console.log('rule.base', rule.base)
            console.log('this.baseId', this.baseId)
            console.log('parsingDepth', parsingDepth)
            console.log('rule.position', rule.position)
            console.log('this.baseId == rule.base', this.baseId == rule.base)
            console.log('parsingDepth == rule.position', parsingDepth == rule.position)
            if (this.baseId == rule.base && parsingDepth == rule.position) {
              const includeFilter = escalon.escalones.filter(step => {
                return rule.include.includes(step.AppEscalonesCie10Cps.clave)
              })
              console.log('includeFilter', includeFilter)
              escalon.escalones = includeFilter
            }
          }
        });
      }
      return escalon
    },
    onNameSelected (name = '', code = '') {
      let thisString = ''
      const thisCode = this.step.Child.clave + code
      console.log(this.step.Child.clave.toLowerCase())
      if (this.step.Child.clave.toLowerCase() !== 'z'){
        thisString = this.depth >= this.startStringAtDepth ? this.step.Child.name : ''
      }
      this.$emit('onNameSelected', thisString + ' ' + name, thisCode)
    },
    expand: function () {
      // const formData = new FormData()
      // formData.append('escalon_id',this.step.AppEscalonesCie10Cps.id)
      // formData.append('depth',1)
      // formData.append('type', 'complication')
      this.onNameSelected()
      const payload = this.complication ? {
        url: `/${this.step.AppEscalonesCie10Cps.id}/2/0/complication`
      } : `/${this.step.AppEscalonesCie10Cps.id}/2`
      this.$store.dispatch('Complication/getTempStructure', payload).then((res) => {
        this.selectedChild = this._getSteps(res[0])
      })
    }
  },
  computed: {
  },
  data () {
    return {
      selectedChild: {}
    }
  },
  props: {
    baseId: Number,
    rules: {
      type: Array,
      default: () => {
        return []
      }
    },
    step: Object,
    depth: {
      type: Number,
      default: 1
    },
    complication: {
      type: Boolean,
      default: false
    },
    startStringAtDepth: {
      type: Number,
      default: 0
    },
  },
}
</script>

<style scoped>
.v-expansion-panel-header > i {
  flex: 0 0 auto;
}
</style>