<template>
  <v-tab-item class="mt-5">
    <v-btn
      color="green lighten-2"
      dark
      small
      @click="openCie10CPSDialog"
    >
    <v-icon color="white">mdi-plus</v-icon>
    {{condiciones.btnText}}
    </v-btn>
    <h3 class="mt-5">{{condiciones.dialogTitle}}</h3>
    <v-data-table
      :items="items"
      :headers="[
        {text:'Código CIE10-CPS', value:'code'},
        {text:'Nombre', value:'name'}
      ]"
    />
  </v-tab-item>
</template>

<script>
export default {
  methods: {
    openCie10CPSDialog () {
      this.$emit('openCie10CPSDialog', this.condiciones)
    },
  },
  props: {
    condiciones: Object,
    items: Array,
  }
}
</script>

<style>

</style>