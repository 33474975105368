import axios from 'axios'
export default {
  namespaced: true,
  state: {
    structure: [],
    tempStructure: []
  },
  mutations: {
    structure (state, payload) {
      state.structure = payload
    },
    tempStructure (state, payload) {
      state.tempStructure = payload
    },
  },
  actions: {
    index ({commit}, payload) {
      console.log(payload)
      return axios.post('https://navegacion.exinnotch.com/app_cat_cie10_cps_ajax/getEscalonesStructure', payload, {
        // headers: payload.getHeaders()
      }).then((res) => {
        commit('structure', res.data)
        return res.data
      });
    },
    getTempStructure ({commit}, payload) {
      if (payload.url) {
        return axios.get(`https://navegacion.exinnotch.com/app_cat_cie10_cps_ajax/getEscalonesStructure${payload.url}`, {
        }).then((res) => {
          commit('tempStructure', res.data)
          return res.data
        });
      } else {
        return axios.post(`https://navegacion.exinnotch.com/app_cat_cie10_cps_ajax/getEscalonesStructure${payload}`, {
        }).then((res) => {
          commit('tempStructure', res.data)
          return res.data
        });
      }
    }
  },
  getters: {
    structure (state) { return state.structure },
    tempStructure (state) { return state.tempStructure },
  }
}