<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Nota Clínica Protocolizada</h2>
      </v-col>
    </v-row>    <v-row>
      <v-col>
        <h4>Lista de problemas</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template>
          <v-tabs v-model="tabSection">
            <v-tab>Formulación del problema</v-tab>
            <v-tab>Decisión Médica</v-tab>
            <v-tab>Información Médica</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabSection">
            <v-tab-item>
              <template>
                <v-tabs v-model="tabSectionProblemFormulation">
                  <v-tab>Datos Subjetivos</v-tab>
                  <v-tab>Datos Objetivos</v-tab>
                  <v-tab>Diagnósticos</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabSectionProblemFormulation">
                    <v-tab-item>
                      <v-data-table
                        v-model="selectedSubjective"
                        :headers="headersSubjective"
                        :items="symptoms"
                        show-select
                        multiple
                        return-object
                      >
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                      aaaaa
                      <v-data-table
                        v-model="selectedObjective"
                        :headers="headersObjective"
                        :items="signs"
                        show-select
                        multiple
                        return-object
                      >
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-data-table
                        v-model="selectedDiagnosis"
                        :headers="headersDiagnosis"
                        :items="diagnosis"
                        show-select
                        multiple
                        return-object
                      >
                      </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
                <v-tabs v-model="tabSectionMedicalDecision">
                </v-tabs>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-tabs v-model="tabSectionMedicalDecision">
                  <v-tab>Plan Diagnóstico</v-tab>
                  <v-tab>Plan Terapéutico</v-tab>
                  <v-tab>Plan Educativo</v-tab>
                </v-tabs>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-tabs v-model="tabSectionMedicalInformation">
                  <v-tab>Información para el médico</v-tab>
                  <v-tab>Información para el paciente</v-tab>
                  <v-tab>GPCs</v-tab>
                </v-tabs>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('ProtocolAPI/escalones', this.$route.params.protocolId)
  },
  computed: {
    protocol () {
      return this.$store.getters['ProtocolAPI/protocol']
    },
    escalones () {
      return this.$store.getters['ProtocolAPI/escalones']
    },
    symptoms () {
      return this.escalones.symptoms ? this.escalones.symptoms : []
    },
    signs () {
      return this.escalones.signs.length ? this.escalones.signs : []
    },
    diagnosis () {
      return this.escalones.diagnosis.length ? this.escalones.diagnosis : []
    }
  },
  data () {
    return {
      selectedSubjective: [],
      selectedObjective: [],
      selectedDiagnosis: [],
      headersSubjective: [
        {
          text: 'Síntoma', value:'title.name'
        }
      ],
      headersObjective: [
        {
          text: 'Signo', value:'title.name'
        }
      ],
      headersDiagnosis: [
        {
          text: 'Diagnóstico', value:'title.name'
        }
      ],
      tabSection: null,
      tabSectionProblemFormulation: null,
      tabSectionMedicalDecision: null,
      tabSectionMedicalInformation: null,
    }
  }
}
</script>

<style>

</style>