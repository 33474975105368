<template>
  <v-menu
    v-model="datePickerMenu"
    :disabled="disabled"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
          :disabled="disabled"
          v-model="parsedDate"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          ></v-text-field>
      </template>
      <div>{{value}}</div>
      <v-date-picker
        v-model="date"
        locale="es-MX"
        @input="parseInput"
      ></v-date-picker>
  </v-menu>
</template>

<script>

export default {
  mounted() {
    this.parseInput(this.value)
  },
  methods: {
    parseInput (input) {
      this.date = input
      this.datePickerMenu = false
      if (!this.date) return null
      const date = this.date.substring(0,10)
      const [year,month,day] = date.split('-')
      this.parsedDate = `${day.padStart(2, '0')}/${this.ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`
      this.$emit('input', this.date)
    }
  },
  watch: {
      value(value) {
        if (value) {
          this.date = value.substring(0,10);
          this.parseInput(this.date)
        }
      }
  },
  props: ['value', 'label', 'disabled'],
  data() {
    return {
        datePickerMenu: false,
        date: null,
        parsedDate: null,
        ENV: {
          months: {
            1: {
              name: 'enero',
              abv: 'ene'
            },
            2: {
              name: 'febrero',
              abv: 'feb'
            },
            3: {
              name: 'marzo',
              abv: 'mar'
            },
            4: {
              name: 'abril',
              abv: 'abr'
            },
            5: {
              name: 'mayo',
              abv: 'may'
            },
            6: {
              name: 'junio',
              abv: 'jun'
            },
            7: {
              name: 'julio',
              abv: 'jul'
            },
            8: {
              name: 'agosto',
              abv: 'ago'
            },
            9: {
              name: 'septiembre',
              abv: 'sept'
            },
            10: {
              name: 'octurbe',
              abv: 'oct'
            },
            11: {
              name: 'noviembre',
              abv: 'nov'
            },
            12: {
              name: 'diciembre',
              abv: 'dic'
            },
          }
        }
    }
  }
}
</script>

<style>

</style>