<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      {{title}}
    </v-card-title>
    <v-card-text class="pt-5">
      <h3>Estudio seleccionado: </h3><h5>{{fullNameStudy}} </h5><h5 v-if="fullCodeStudy.length>0">({{fullCodeStudy}})</h5>
      <div v-if="Array.isArray(structure)">
        <div v-for="(step, index) in structure" :key="index">
          <div v-if="Array.isArray(step) && step.length > 0">
            <LCERecursive :complication="complication" @itemSelected="itemSelected" :baseId="Number(step[0].AppEscalonesCie10Cps.id)" :rules='rules' :startStringAtDepth = 3 @onNameSelected = "onNameSelected" :step="step[0]"/>
          </div>
          <div v-if="typeof step === 'object' && step.AppEscalonesCie10Cps">
            <LCERecursive :complication="complication" :baseId="Number(step.AppEscalonesCie10Cps.id)" :rules='rules' :startStringAtDepth = 3 @onNameSelected = "onNameSelected" :step="step[0]"/>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="step in structure.escalones" :key="step.Child.id">
          <LCERecursive :complication="complication" :baseId="cie10cpsId" :depth=2 :rules='rules' :startStringAtDepth = 1 @onNameSelected = "onNameSelected" :step="step"/>
        </div>
      </div>
      <v-btn class="green lighten-2" dark>Agregar</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import LCERecursive from './searchLCERecursive.vue'
export default {
  components: {
    LCERecursive
  },
  mounted () {
    const payload = this.complication ? '2/0/complication' : '2'
    console.log(payload)
    if (!Array.isArray(this.cie10cpsId)) {
      this.$store.dispatch('Cie10CPS/levelCie10CPS', `/${this.cie10cpsId}/${payload}`)
    } else {
      this.$store.dispatch('Cie10CPS/levelCie10CPS', this.cie10cpsId)
    }
  },
  computed: {
    structure () {
      if (this.$store.getters['Cie10CPS/cie10cps'].length > 0 && Array.isArray(this.cie10cpsId)) {
        return this.$store.getters['Cie10CPS/cie10cps']
      }
      if (this.$store.getters['Cie10CPS/cie10cps'].length > 0 && !Array.isArray(this.cie10cpsId)) {
        return this.$store.getters['Cie10CPS/cie10cps'][0]
      }
      return this.$store.getters['Cie10CPS/cie10cps']
    }
  },
  watch: {
    cie10cpsId () {
      if (!Array.isArray(this.cie10cpsId)) {
        const payload = this.complication ? '2/0/complication' : '2'
        this.$store.dispatch('Cie10CPS/levelCie10CPS', `/${this.cie10cpsId}/${payload}`)
      } else {
        this.$store.dispatch('Cie10CPS/levelCie10CPS', this.cie10cpsId)
      }
    }
  },
  methods: {
    itemSelected() {
      console.log('Escalones Item Selected')
    },
    onNameSelected (name, code) {
      this.fullNameStudy = name
      this.fullCodeStudy = this.baseCode + code
      if (code.length == 7) {
        alert('selected 7')
        this.$emit('input', {name, code})
      }
    },
    filter (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    },
  },
  props: {
    complication: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return []
      }
    },
    baseCode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    cie10cpsId: [Number, Array],
    value: {},
    multipleSelect: {
      type: Boolean,
      default: false
    },
    btnIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fullNameStudy: '',
      fullCodeStudy: '',
      searchField: '',
      structureHeaders: [
        {text:"nombre", value:"Child.name"},
        {text: '', value: 'data-table-expand' },
      ]
    }
  }
}
</script>

<style>

</style>