const CIE10CPSProcedimientoMedicoQuirurgico = 778
const CIE10CPSGinecologiaYObstetricia = 779
const CIE10CPSNuclearMedicineId = 789
const CIE10CPSImagenId = 788
const CIE10CPSAudiologiaId = 1840
const CIE10CPSPruebasPsicologicasId = 1857
const CIE10CPSSistemasFisiologicosId = 1561
const CIE10CPSAdministracionSanguineaTransfusionPor = 1557
const CIE10CPSColocacion = 780
const CIE10CPSTerapeuticNuclearMedicine = 35717
const CIE10CPSTerapeuticRadioterapia = 790
const CIE10CPSOsteopatia = 785
const CIE10CPSQuiropractica = 787
const CIE10CPSRehabilitation = 791
const CIE10CPSCausaLCE = 223394
const CIE10CPSLesionLCE = 224008
const apiURL = 'https://navegacion.exinnotch.com'
const simpleNotesUrl = 'https://navegacion.exinnotch.com/api/ApiNotaProtocolizada'
const patientCreateUrl = 'https://navegacion.exinnotch.com/ApiPatient/api_create_patient'
export default {
  apiURL,
  simpleNotesUrl,
  patientCreateUrl,
  CIE10CPSProcedimientoMedicoQuirurgico,
  CIE10CPSGinecologiaYObstetricia,
  CIE10CPSImagenId,
  CIE10CPSNuclearMedicineId,

  CIE10CPSTerapiaDeMedicinaNuclearSistémicaPara: 35717,
  //TERCirugía
  CIE10CPSTerapeuticCirugiaCondiciones: {
    base: CIE10CPSProcedimientoMedicoQuirurgico,
    dialogTitle: 'CIE10-CPS: CIRUGÍA',
    btnText: 'Agregar prueba de cirugía',
    //base, posicion, claves
    rules: []
  },
  //LaLesionDecausaExterna
  CIE10CPSLesionLCE: {
    base: CIE10CPSLesionLCE,
    dialogTitle: 'LA LESIÓN DE CAUSA EXTERNA',
    btnText: 'Agregar LECE',
    //base, posicion, claves
    rules: []
  },
  //LaCausaDeLaLesion
  CIE10CPSCausaLCE: {
    base: CIE10CPSCausaLCE,
    dialogTitle: 'LA CAUSA DE LA LESIÓN',
    btnText: 'Agregar LECE',
    //base, posicion, claves
    rules: []
  },
  //TEROnstetricia
  CIE10CPSTerapeuticObstetriciaCondiciones: {
    base: CIE10CPSGinecologiaYObstetricia,
    dialogTitle: 'CIE10-CPS: OBSTETRICIA',
    btnText: 'Agregar prueba de obstetricia',
    //base, posicion, claves
    rules: []
  },
  //TERTransfusion
  CIE10CPSTerapeuticTransfusionesCondiciones: {
    base: CIE10CPSAdministracionSanguineaTransfusionPor,
    dialogTitle: 'CIE10-CPS: TRANSFRUSIONES',
    btnText: 'Agregar prueba de transfusiones',
    //base, posicion, claves
    rules: []
  },
  //TERColocación
  CIE10CPSTerapeuticColocacion: {
    base: CIE10CPSColocacion,
    dialogTitle: 'CIE10-CPS: COLOCACIÓN',
    btnText: 'Agregar prueba de colocación',
    //base, posicion, claves
    rules: []
  },
  //TERMedicina Nuclear
  CIE10CPSTerapeuticNuclearMedicine: {
    base: CIE10CPSTerapeuticNuclearMedicine,
    dialogTitle: 'CIE10-CPS: MEDICINA NUCLEAR',
    btnText: 'Agregar prueba de medicina nuclear',
    //base, posicion, claves
    rules: []
  },
  //TERRadioterapia
  CIE10CPSTerapeuticRadioterapia: {
    base: CIE10CPSTerapeuticRadioterapia,
    dialogTitle: 'CIE10-CPS: RADIOTERAPIA',
    btnText: 'Agregar prueba de radioterapia',
    //base, posicion, claves
    rules: []
  },
  //TERRehabilitación
  CIE10CPSMedicinaFisicayRehabilitación: {
    base: [
      CIE10CPSOsteopatia,
      CIE10CPSQuiropractica,
      CIE10CPSRehabilitation
    ],
    dialogTitle: 'CIE10-CPS: MEDICINA FÍSICA',
    btnText: 'Agregar prueba de medicina física',
    //base, posicion, claves
    rules: [
      {
        base: CIE10CPSRehabilitation,
        position: 2,
        include: [
          '0'
        ]
      },
      {
        base: CIE10CPSRehabilitation,
        position: 3,
        include: [
          '6', '7', '8', '9', 'B', 'C', 'D', 'F'
        ]
      },
    ]
  },

  //PLAN DIAGNÓSTICO
  //Patología
  CIE10CPSPatologiaCondiciones: {
    base: [
      CIE10CPSProcedimientoMedicoQuirurgico,
      CIE10CPSGinecologiaYObstetricia
    ],
    dialogTitle: 'CIE10-CPS: PATOLOGÍA',
    btnText: 'Agregar prueba de patología',
    //base, posicion, claves
    rules: [
      {
        base: CIE10CPSProcedimientoMedicoQuirurgico,
        position: 3,
        include: [
          '9', 'B', 'C', 'D', 'F', 'J', 'T'
        ]
      },
      {
        base: CIE10CPSGinecologiaYObstetricia,
        position: 3,
        include: [
          '9', 'A', 'D', 'E', 'T','B', 'C', 'F', 'J', 'T'
        ]
      },
    ]
  },
  CIE10CPSEndoscopiaCondiciones: {
    base: [
      CIE10CPSProcedimientoMedicoQuirurgico,
    ],
    dialogTitle: 'CIE10-CPS: ENDOSCOPIA',
    btnText: 'Agregar prueba de endoscopia',
    //base, posicion, claves
    rules: [
      {
        base: CIE10CPSProcedimientoMedicoQuirurgico,
        position: 3,
        include: [
          'J'
        ]
      },
      {
        base: CIE10CPSProcedimientoMedicoQuirurgico,
        position: 5,
        include: [
          '4', '8'
        ]
      },
    ]
  },
  CIE10CPSMedicinaNuclearCondiciones: {
    base: CIE10CPSNuclearMedicineId,
    dialogTitle: 'CIE10-CPS: MEDICINA NUCLEAR',
    btnText: 'Agregar estudio de medicina nuclear',
    //base, posicion, claves
    rules: [
      {
        base: CIE10CPSNuclearMedicineId,
        position: 3,
        exclude: ['7']
      }
    ]
  },
  CIE10CPSImagenCondiciones: {
    base: CIE10CPSImagenId,
    dialogTitle: 'CIE10-CPS: IMAGEN',
    btnText: 'Agregar estudio de imagen',
    //base, posicion, claves
    rules: []
  },
  CIE10CPSAudiologiaCondiciones: {
    base: CIE10CPSAudiologiaId,
    dialogTitle: 'CIE10-CPS: AUDIOLOGÍA',
    btnText: 'Agregar estudio de audiología',
    //base, posicion, claves
    rules: []
  },
  CIE10CPSPruebasPsicologicasCondiciones: {
    base: CIE10CPSPruebasPsicologicasId,
    dialogTitle: 'CIE10-CPS: PRUEBAS PSICOLÓGICAS',
    btnText: 'Agregar prueba psicológica',
    //base, posicion, claves
    rules: []
  },
  CIE10CPSSistemasFisiologicosCondiciones: {
    base: CIE10CPSSistemasFisiologicosId,
    dialogTitle: 'CIE10-CPS: MEDICIÓN Y MONITOREO',
    btnText: 'Agregar estudio de gabinete',
    //base, posicion, claves
    rules: []
  },
  CIE10CPSDrenajeDe: 1529,
  CIE10CPSAborto: 1530,
  CIE10CPSExtraccionDe: 1531,
  CIE10CPSPartoDe: 1532,
  CIE10CPSTransplanteEn: 1539,
  CIE10CPSEscisiónDe: 885,
  CIE10CPSExtirpaciónDeMaterialDe: 886,
  CIE10CPS0ExtracciónDe: 890,
  CIE10CPSExtracciónDe: 886,
  CIE10CPSFragmentaciónDeMaterialEn: 891,
  CIE10CPSInspecciónDe: 893,
  CIE10CPSResecciónDe: 900,

  //Endoscopia
  CIE10CPSInspeccionDe: 1184,
  CIE10CPSNaturalOrifice: 113129,
  CIE10CPSArtificialOrifice: 113138,
}