<template>
  <v-card
    class=""
  >
    <v-container>
      <v-row>
        <v-col>
          <h3>Protocolo de enfermedades y problemas</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :md="$route.params.protocolId ? 10 : 12">
          <v-text-field v-model="model.AppCatProtocolo.name" label="Nombre del protocolo"></v-text-field>
          <v-btn color="primary" @click.stop="createProtocol()" v-if="!protocoloId" class="custom-pointer">Crear Protocolo<v-icon>mdi-plus</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row v-if="$route.params.protocolId">
        <v-col cols="12" md="2">
          <v-checkbox v-model="model.AppCatProtocolo.active" @click="setVariable('AppCatProtocolo.active')" label="Activo"></v-checkbox>
        </v-col>
        <v-col cols="12" md=6>
          <v-autocomplete
            v-model="model.Title"
            :loading="cie10Loading"
            :items="cie10List"
            chips
            small-chips
            item-text="name"
            :search-input.sync="searchCie10"
            cache-items
            class="mx-4"
            label="CIE10"
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <h5 class="custom-pointer">Mostrar explicación del título <button><v-icon>mdi-chevron-down</v-icon></button></h5>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field label="Comentario" v-model="model.AppCatProtocolo.comment"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select label="Género" @change="setVariable('Edad.genero')" v-model="model.Edad.genero" :items="genders" item-text="name" item-value="value"></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-checkbox label="Embarazadas" @change="setVariable('Edad.embarazo')" v-model="model.Edad.embarazo"></v-checkbox>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="12" md="4">
              <v-checkbox v-model="model.AppCatProtocolo.problema_agudo" @click="setVariable('AppCatProtocolo.problema_agudo')" label="Agudo"></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox label="Crónico" v-model="model.AppCatProtocolo.problema_cronico" @click="setVariable('AppCatProtocolo.problema_cronico')"></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-checkbox label="Preventivo" v-model="model.AppCatProtocolo.problema_preventivo" @click="setVariable('AppCatProtocolo.problema_preventivo')"></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" class="text-left">
          <h3>Rangos de Edad</h3>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col><v-text-field label="Inicio" v-model="ini_int"></v-text-field></v-col>
            <v-col><v-select label="Inicio Unidad" :items="timeUnits" item-text="name" item-value="value" v-model="ini_unit"></v-select></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-col><v-text-field label="Fin" v-model="fin_int"></v-text-field></v-col>
            <v-col><v-select label="Fin Unidad" :items="timeUnits" item-text="name" item-value="value" v-model="fin_unit"></v-select></v-col>
          </v-row>
        </v-col>
      <v-autocomplete
        v-model="model.AppCatEspecialidadMedica"
        :loading="specialtiesLoading"
        :items="specialties"
        chips
        small-chips
        item-text="AppCatEspecialidadMedica.name"
        item-value="AppCatEspecialidadMedica"
        :search-input.sync="searchSpecialties"
        cache-items
        class="mx-4"
        label="Especialidades"
        return-object
        multiple
      ></v-autocomplete>
      <v-col cols="12">
        <v-input>
          <v-text-field label="Causes" v-model="causesText"/>
          <v-btn color="primary" @click.stop="addCauses()">Añadir <v-icon>mdi-plus</v-icon></v-btn>
        </v-input>
        <ul class="text-left">
          <li v-for="(cause, index) in causes" :key="index"> {{cause.name}}</li>
        </ul>
      </v-col>
      <v-col cols="12">
          <v-text-field label="GPC Nombre" v-model="gpcInput.name"/>
        <v-input>
          <v-text-field label="GPC Link" v-model="gpcInput.url"/>
          <v-btn color="primary" @click.stop="addGPCs()">Añadir <v-icon>mdi-plus</v-icon></v-btn>
        </v-input>
        <ul class="text-left">
          <li v-for="gpc in model.AppCatGpc" :key="gpc.name"> {{gpc.name}}: {{gpc.url}}</li>
        </ul>
      </v-col>
      <v-col>
        <v-card>
          <v-tabs
            background-color="primary"
            v-model="ladderTabSelected"
            dark
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab v-for="(ladderTab, index) in ladderTabs" :key="index">
              {{ladderTab}}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="ladderTabSelected">
            <v-tab-item v-for="(ladderTab, index) in ladderTabs" :key="index"
            >
              <v-container v-if="ladderTab == 'Formulación del Problema'">
                <v-row>
                  <v-col class="text-left">
                    <v-expansion-panels v-if="model.EscalonesProtocolos">
                      <v-expansion-panel
                        v-for="(protocol,i) in model.EscalonesProtocolos.filter((element) => element.parent_id !== null)"
                        :key="i"
                      >
                        <v-expansion-panel-header>
                          {{protocol.id}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Datos Subjetivos <v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Datos Objetivos <v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Diagnósticos <v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="ladderTab == 'Decisión Médica'">
                <v-row>
                  <v-col class="text-left">
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Plan Diagnóstico <v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Plan Terapéutico <v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Plan Terapéutico CIE10CPS<v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="custom-pointer">
                      <v-list-item-content>
                        <v-list-item-title>Plan Educativo <v-icon>mdi-chevron-down</v-icon></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { debounce } from "debounce"
import axios from 'axios'
export default {
  mounted () {
    if (this.$route.params.protocolId) {
      return this.$store.dispatch('ProtocolAPI/protocol', this.$route.params.protocolId).then(() => {
        const originalAppCatProtocolo = this.$store.getters['ProtocolAPI/protocol']
        this.model = originalAppCatProtocolo
        this.setIniInt()
        this.setIniUnit()
        this.setFinInt()
        this.setFinUnit()
        this.setCauses()
        this.setSpecialties()
        this.setCie10List()
      })
    }
  },
  methods: {
    debouncedSetAges : debounce(function (isInit, unit_key) {
      if(isInit && (this.ini_int == undefined || this.ini_unit == undefined)) {
        return
      } else if (!isInit && (this.fin_int == undefined || this.fin_unit == undefined)) {
        return
      }
      if (isInit){
        this.model.Edad.ini_dias = 0
        this.model.Edad.ini_meses = 0
        this.model.Edad.ini_anos = 0
      }else{
        this.model.Edad.fin_dias = 0
        this.model.Edad.fin_meses = 0
        this.model.Edad.fin_anos = 0
      }
      this.model.Edad[unit_key] = isInit ? this.ini_int : this.fin_int
      this.setVariable('Edad')
    }, 1000),
    addCauses () {
      this.causes.push({name: this.causesText})
    },
    debouncedSetVariable: debounce((store, payload) => {
      store.dispatch('ProtocolAPI/edit', payload)
    }, 1000),
    setVariable(key, value = false) {
      let payload = {
        id: this.protocoloId,
        model: {
        }
      }
      let currentModel = false
      /* eslint no-unused-vars: 0, curly: 2 */
      const model = this.model
      key.split('.').forEach((item, index, keys) =>{
        if (keys.length == 1) {
          payload.model[item] = value ? value : eval(`model.${key}`)
          return
        }
        if (!currentModel) {
          payload.model[item] = {}
          currentModel = {}
          currentModel[item] = {}
        }
        else {
          currentModel[item] = {}
        }
        if (index == (keys.length - 1)) {
          currentModel[item] = value ? value : eval(`model.${key}`)
        }
        currentModel = payload.model[item]
      })
      this.$store.dispatch('ProtocolAPI/edit', payload)
    },
    addGPCs () {
      this.model.AppCatGpc.push(this.gpcInput)
      this.setVariable('AppCatGpc')
    },
    createProtocol(){
      this.$store.dispatch('ProtocolAPI/create', this.protocol).then(
        (res) => {
          this.$router.push({name: 'ProtocolsEdit', params: {protocolId: res.id}})
        }
      )
    },
    setIniInt () {
      if (this.model.Edad.ini_anos != 0) {
        this.ini_int = this.model.Edad.ini_anos
      } else if (this.model.Edad.ini_meses != 0) {
        this.ini_int = this.model.Edad.ini_meses
      } else if (this.model.Edad.ini_dias != 0) {
        this.ini_int = this.model.Edad.ini_dias
      }else{
        this.ini_int = 0
      }
    },
    setIniUnit () {
      if (this.model.Edad.ini_anos != 0) {
        this.ini_unit = 'anos'
      } else if (this.model.Edad.ini_meses != 0) {
        this.ini_unit = 'meses'
      }else{
        this.ini_unit = 'dias'
      }
    },
    setFinInt () {
      if (this.model.Edad.fin_anos != 0) {
        this.fin_int = this.model.Edad.fin_anos
      } else if (this.model.Edad.fin_meses != 0) {
        this.fin_int = this.model.Edad.fin_meses
      } else if (this.model.Edad.fin_dias != 0) {
        this.fin_int = this.model.Edad.fin_dias
      }else{
        this.fin_int = 0
      }
    },
    setFinUnit () {
      if (this.model.Edad.fin_anos != 0) {
        this.fin_unit = 'anos'
      } else if (this.model.Edad.fin_meses != 0) {
        this.fin_unit = 'meses'
      } else {
        this.fin_unit = 'dias'
      }
    },
    setCauses() {
      this.causes = this.model.ProtocoloCauses
    },
    setSpecialties(){
      this.specialties = this.model.AppCatEspecialidadMedica.map((element) => {
        return {AppCatEspecialidadMedica: element}
      })
    },
    setCie10List(){
      this.cie10List = [this.model.Title]
    }
  },
  watch: {
    searchSpecialties: debounce (function () {
      if (this.searchSpecialties !== '' && this.searchSpecialties !== null){
        axios.get(`https://navegacion.exinnotch.com/api/specialties?string=${this.searchSpecialties}&fields=id,name`).then((res) => {
          this.specialties = res.data
        })
      }
    }, 1000),
    searchCie10: debounce (function () {
      if (this.searchCie10 !== '' && this.searchCie10 !== null){
        axios.get(`https://navegacion.exinnotch.com/api/cie10?string=${this.searchCie10}&fields=id,name`).then((res) => {
          this.cie10List = res.data.map((element) => {
            return element.AppHospCie10
          })
        })
      }
    }, 1000),
    comment() {
      this.debouncedSetVariable(this.$store, {
        id: this.protocoloId,
        model: {
          'AppCatProtocolo': {
             comment: this.model.AppCatProtocolo['comment']
          }
        }
      })
    },
    ini_int(){
      this.debouncedSetAges(true, 'ini_'+this.ini_unit)
    },
    fin_int(){
      this.debouncedSetAges(false, 'fin_'+this.fin_unit)
    },
    ini_unit() {
      this.debouncedSetAges(true, 'ini_'+this.ini_unit)
    },
    fin_unit() {
      this.debouncedSetAges(false, 'fin_'+this.fin_unit)
    },
    causes () {
      this.setVariable('ProtocoloCauses', this.causes)
    },
  },
  computed: {
    originalAppCatProtocolo () {
      const originalAppCatProtocolo = this.$store.getters['ProtocolAPI/protocol']
      return originalAppCatProtocolo
    },
    comment () {
      return this.model.AppCatProtocolo.comment
    },
  },
  data () {
    return {
      searchCie10: null,
      cie10Loading: false,
      cie10List: [],
      specialtiesLoading: false,
      searchSpecialties: '',
      specialties: [],
      protocoloId: this.$route.params.protocolId,
      ini_int: this.initialIniInt,
      ini_unit: this.initialIniUnit,
      fin_int: null,
      fin_unit: null,
      model: {
        'AppCatProtocolo':{
          name: '',
          active: '',
          comment: '',
          problema_agudo: null,
        },
        'Edad': {
          name: '',
          descripcion: '',
          ini_anos: '',
          ini_meses: '',
          ini_dias: '',
          genero: '',
          embarazo: '',
        }
      },
      ladderTabs: ['Formulación del Problema', 'Decisión Médica'],
      ladderTabSelected: null,
      gpcInput: {
        name: '',
        url: ''
      },
      gpcs: [],
      causes: [],
      causesText: '',
      genders: [{
        name:'Ambos',
        value:'A'
      },
      {
        name:'Hombre',
        value:'H'
      },
      {
        name:'Mujer',
        value:'M'
      }],
      timeUnits: [
        {name:'Días', value: 'dias'},
        {name:'Meses', value: 'meses'},
        {name:'Años', value: 'anos'}
      ],
    }
  }
}
</script>

<style>
.custom-pointer {
   cursor: pointer;
}
</style>