import axios from 'axios'
export default {
  namespaced: true,
  state: {
    user: {},
    all: {},
    modelName: 'Cie10',
    cie10s: [],
    cie10codes: [],
    cedula: null,
    curp: null,
    escalones: {
      signs: [],
      symptoms: [],
      diagnosis: [],
    },
    selectedEntity: {}
  },
  mutations: {
    all (state, payload) {
      state.all = payload
    },
    cie10s (state, payload) {
      state.cie10s = payload
    },
    cie10codes (state, payload) {
      state.cie10codes = payload
    },
    protocol (state, payload) {
      state.protocol = payload
    },
    escalones (state, payload) {
      state.escalones = payload
    },
    cedula (state, payload) {
      state.cedula = payload
    },
    curp (state, payload) {
      state.curp = payload
    },
    user (state, payload) {
      state.user = payload
    },
    selectedEntity (state, payload) {
      sessionStorage.setItem('selectedEntity', JSON.stringify(payload))
      state.selectedEntity = payload
    },
    refreshPermissions (state, payload) {
      console.log(payload)
      let selectedEntity = {}
      try{
        selectedEntity = JSON.parse(sessionStorage.getItem('selectedEntity'))
      } catch { console.log('no electedEntity')}
      console.log(selectedEntity)
      selectedEntity['EsREsUsuPermiso'] = payload
      sessionStorage.setItem('selectedEntity', JSON.stringify(selectedEntity))
      state.selectedEntity = selectedEntity
    },
    removePermission (state, payload) {
      const newObject = {}
      Object.keys(state.selectedEntity.EsREsUsuPermiso).forEach((keyName) => {
        const permission = state.selectedEntity.EsREsUsuPermiso[keyName]
        if (permission && permission.Usuario && permission.id != payload) {
          newObject[keyName] = permission
        }
      })
      state.selectedEntity.EsREsUsuPermiso = newObject
    },
    createPermission (state, payload) {
      state.selectedEntity.EsREsUsuPermiso[payload.id] = payload
      const newObject = {}
      Object.keys(state.selectedEntity.EsREsUsuPermiso).forEach((keyName) => {
        const permission = state.selectedEntity.EsREsUsuPermiso[keyName]
        newObject[keyName] = permission
      })
      newObject[payload.id] = payload
      state.selectedEntity.EsREsUsuPermiso = newObject
    },
  },
  actions: {
    login({commit}, payload) {
      var formData = new FormData();
      for ( var key in payload.Usuario ) {
        formData.append(`Usuario[${key}]`, payload['Usuario'][key]);
      }
      return axios.post(`https://navegacion.exinnotch.com/api/users/login`, formData)
      .then(function (response) {
        commit('user', response.data)
        sessionStorage.setItem('user', JSON.stringify(response.data))
        return response.data;
      })
      .catch(function (error) {
        return (error);
      });
    },
    validateCurp ({commit, state}, payload) {
      return axios.get(`https://navegacion.exinnotch.com/api/users/validateCurp?curp=${payload}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return (error);
      });
    },
    validateCedula ({commit, state}, payload) {
      return axios.get(`https://navegacion.exinnotch.com/api/users/validateCedula?cedula=${payload}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return (error);
      });
    },
    selectTabla ({commit, state}, payload) {
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return axios.post('https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla', payload, config).then((res) => {
        commit('cie10s', res.data)
        return res.data
      });
    },
    levelCie10 ({commit, state}, payload) {
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      return axios.get(`https://navegacion.exinnotch.com/api/Cie10?${payload}`,config).then((res) => {
        commit('cie10codes', res.data)
        return res.data
      });
    },
    create ({commit, state}, payload) {
      var formData = new FormData();
      for ( var key in payload.Usuario ) {
        formData.append(`Usuario[${key}]`, payload['Usuario'][key]);
      }
      return axios.post('https://navegacion.exinnotch.com/api/ApiUser/create', formData).then((res) => {
        return res.data
      }).catch((e) => {
        console.log(e.response.data)
      });
    },
    protocol ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla/'+payload).then((res) => {
        commit('protocol', res.data)
      });
    },
    escalones ({commit, state}, payload) {
      return axios.get('https://navegacion.exinnotch.com/api/escalones_protocolos/'+payload).then((res) => {
        commit('escalones', res.data)
      });
    },
    edit ({commit, state}, payload) {
      return axios.put(`https://navegacion.exinnotch.com/app_hosp_cie10_ajax/select_tabla/${payload.id}/edit`, payload.model).then((res) => {
        commit('protocol', res.data)
      });
    },
    show ({commit, state}, payload) {
      return axios.get(`https://navegacion.exinnotch.com/api/users`).then((res) => {
        commit('user', res.data)
      });
    },
    index ({commit, state}, payload) {
      return axios.get(`https://navegacion.exinnotch.com/api/users`).then((res) => {
        commit('all', res.data)
      });
    }
  },
  getters: {
    all (state) { return state.all},
    cie10s (state) { return state.cie10s},
    cie10codes (state) { return state.cie10codes},
    protocol (state) { return state.protocol},
    escalones (state) { return state.escalones},
    curp (state) { return state.curp},
    user (state) { 
      if (state.user && state.user.name) return state.user
      try {
        return JSON.parse(sessionStorage.getItem('user'))
      } catch{
        return null
      }
    },
    selectedEntity (state) {
      return __getSelectedEntity(state)
    },
    permissionList (state) { 
      const entity = state.selectedEntity
      if (!(entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id)) return []
      const permissions = Object.keys(entity.EsREsUsuPermiso).map((element, key) => {
        const permission = entity.EsREsUsuPermiso[key]
        return permission
      })
      return permissions
    },
  }
}

function __getSelectedEntity(state) {
  if (!(state.selectedEntity && state.selectedEntity.name)){
    state.selectedEntity = JSON.parse(sessionStorage.getItem('selectedEntity'))
  }
  return state.selectedEntity
}
