import axios from 'axios'
export default {
  namespaced: true,
  state: {
    cie10cps: []
  },
  mutations: {
    cie10cps (state, payload) {
      state.cie10cps = payload
    },
    clearCie10CPS (state, ) {
      state.cie10cps = []
    },
    addCie10CPS (state, payload) {
      state.cie10cps.push(payload)
    }
  },
  actions: {
    //first parameter in payload id of cps
    //second parameter in payload how many levels
    levelCie10CPS ({commit, state}, payload) {
      commit('clearCie10CPS')
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      if (!Array.isArray(payload)) {
        return axios.get(`https://navegacion.exinnotch.com/AppCatCie10CpsAjax/getEscalonesStructure${payload}`,config).then((res) => {
          commit('cie10cps', res.data)
          return res.data
        });
      } else {
        payload.forEach((id) => {
          axios.get(`https://navegacion.exinnotch.com/AppCatCie10CpsAjax/getEscalonesStructure/${id}/2`,config).then((res) => {
            commit('addCie10CPS', res.data)
          });
        })
      }
    },
  },
  getters: {
    cie10cps (state) { return state.cie10cps}
  }
}