<template>
  <v-container>
    <DialogPermisison v-model="showPermissionDialog"/>
    <v-row>
      <v-col>
        <h2>CONFIGURAR</h2>
        <h4>{{entity.name}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn @click="$router.push({name: 'AgendaIndex'})" class="green lighten-2" dark><v-icon>mdi-calendar</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="configurationTabs">
          <v-tab>Datos</v-tab>
          <v-tab>Usuarios</v-tab>
          <v-tab>Consulta</v-tab>
          <v-tabs-items v-model="configurationTabs">
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-text-field label="Nombre del establecimiento" outlined v-model="edittableEntity.name"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Dirección" outlined v-model="edittableEntity.direccion"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Código postal" outlined v-model="edittableEntity.cp"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Colonia" outlined v-model="edittableEntity.colonia"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Colonia" outlined v-model="edittableEntity.localidad"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Teléfono" outlined v-model="edittableEntity.telefono"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col><v-btn @click="showPermissionDialog = true" class="green lighten-2" dark>Añadir permiso</v-btn></v-col>
                <v-col><v-btn v-if="hideUserPersonalTable" @click="hideUserPersonalTable = false" class="lighten-2">Cancelar</v-btn></v-col>
              </v-row>
              <v-data-table :items="userPermissions" :headers="[
                  {text: 'Nombre', value: 'Usuario.nombre_completo'},
                  {text: 'Tipo', value: ''},
                  {text: 'Acciones', value: 'actions'},
                ]"
                v-if="!this.hideUserPersonalTable"
                :items-per-page="10"
              >
                <template v-slot:item.actions="{item}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="deletePermission(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-delete</v-icon>
                    </template>
                    <span>Remover permiso</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="modifyPermission(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                    </template>
                    <span>Editar permiso</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-col v-else>
                <h3>Horario de atención para el usuario</h3>
                <h3>{{selectedUserPermission ? selectedUserPermission.Usuario.nombre_completo : ''}}</h3>
                <v-row>
                  <v-col cols=6>
                    <h4 class="mx auto mt-5">Duración primera vez</h4>
                    <v-select v-model="creatingPermission.first_time_mins" outlined :items="timeOptions" item-value="value" item-text="text"/>                
                  </v-col>
                  <v-col cols=6>
                    <h4 class="mx auto mt-5">Duración consulta subsecuente</h4>
                    <v-select v-model="creatingPermission.subsecuent_time_mins" outlined :items="timeOptions" item-value="value" item-text="text"/>  
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Lunes</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.monday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.monday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Martes</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.tuesday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.tuesday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Miercoles</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.wednesday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.wednesday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Jueves</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.thursday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.thursday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Viernes</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.friday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.friday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Sabado</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.saturday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.saturday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols=3>
                    <h4 class="mx auto mt-5">Domingo</h4>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.sunday.startTime" outlined label="Hora de inicio"></v-text-field>
                  </v-col>
                  <v-col cols=4>
                    <v-text-field v-model="creatingPermission.sunday.endTime" outlined label="Hora de fin"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col><v-btn v-if="hideUserPersonalTable" @click="hideUserPersonalTable = false" class="lighten-2">Cancelar</v-btn></v-col>
                  <v-col>
                    <v-btn class="green lighten-2" dark @click="addUserPermission(selectedUserPermission)">Añadir permiso</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col><h3>Hora de inicio</h3></v-col>
                <v-col><h3>Hora de fin</h3></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-time-picker
                    format="ampm"
                    landscape
                    scrollable
                    ></v-time-picker>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogPermisison from './DialogPermisison.vue';
export default {
  components: {
    DialogPermisison
  },
  mounted () {
    this.$store.dispatch('EsConfUsuAcceso/index', `?hospital_entidad_id=${this.entity.id}`).then( res => {
      this.$store.commit('Usuario/refreshPermissions', res)
    })
    let entity = this.$store.getters['Usuario/selectedEntity']
    entity = entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id ? entity.EsEntidadSalud : {name: ""}
    this.edittableEntity = entity
  },
  computed: {
    permissions () {
      return this.$store.getters['EsConfUsuAcceso/esConfUsuAccesos']
    },
    userPermissions() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      if (!(entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id)) return []
      const permissions = Object.keys(entity.EsREsUsuPermiso).map((element, key) => {
        const permission = entity.EsREsUsuPermiso[key]
        return permission
      })
      return permissions.filter( element => element)
    },
    entity() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      return entity && entity.EsEntidadSalud && entity.EsEntidadSalud.id ? entity.EsEntidadSalud : {name: ""}
    }
  },
  methods: {
    modifyPermission (entityPermission) {
      this.createPersonalModal = true
      this.hideUserPersonalTable = true
      this.selectedUserPermission = entityPermission
      this.creatingPermission = entityPermission.json_schedule ? JSON.parse(entityPermission.json_schedule) : {
        monday: {startTime: null, endTime: null},
        tuesday: {startTime: null, endTime: null},
        wednesday: {startTime: null, endTime: null},
        thursday: {startTime: null, endTime: null},
        friday: {startTime: null, endTime: null},
        saturday: {startTime: null, endTime: null},
        sunday: {startTime: null, endTime: null}
      }
    },
    deletePermission(ERUP) {
      this.$store.dispatch('EsConfUsuAcceso/remove', ERUP.id).then((res) => {
        this.$store.commit('Usuario/removePermission', ERUP.id)
        alert('Permiso eliminado con éxito')
      })
    },
    openAddPersonalForm () {
      this.createPersonalModal = true
    },
  },
  data () {
    return {
      creatingPermission: {
        monday: {startTime: null, endTime: null},
        tuesday: {startTime: null, endTime: null},
        wednesday: {startTime: null, endTime: null},
        thursday: {startTime: null, endTime: null},
        friday: {startTime: null, endTime: null},
        saturday: {startTime: null, endTime: null},
        sunday: {startTime: null, endTime: null}
      },
      showPermissionDialog: false,
      usersSearchString: '',
      createPersonalModal: false,
      configurationTabs: null,
      hideUserPersonalTable: null,
      edittableEntity: {},
    }
  }
}
</script>

<style>

</style>