<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <h2>Lista de encuentros</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="green lighten-2" @click="goToNote()" dark>Agregar nueva nota</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <v-tabs v-model="tab">
          <v-tab>
            Mis encuentros
          </v-tab>
          <v-tab>
            Otros
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <h4>{{entity.name}}</h4>
              <v-data-table :items="thisStablishmentEncounters" :headers="headers" :sort-by="['date']" :sort-desc="[true]">
                <template v-slot:item.actions="{item}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="goToNote(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-stethoscope</v-icon>
                    </template>
                    <span>Ver nota</span>
                  </v-tooltip>
                </template>
              </v-data-table>
          </v-tab-item>
          <v-tab-item>
              <v-data-table :items="otherStablishmentsEncounters" :headers="headers" :sort-by="['date']" :sort-desc="[true]">
                <template v-slot:item.actions="{item}">
                  <v-tooltip bottom v-if="item.type==1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon @click="goToNote(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-stethoscope</v-icon>
                    </template>
                    <span>Ver nota</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.type==3">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-if="item.type==3" @click="goToEncounter(item)" small class="mr-2" v-bind="attrs" v-on="on">mdi-arrow-right</v-icon>
                    </template>
                    <span>Ver encuentro</span>
                  </v-tooltip>
                </template>
              </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    goToEncounter (item) {
      this.$router.push({name: 'EncounterIndex'})
    },
    goToNote (item) {
      this.$router.push({name: 'ClinicalEntriesFreeCreate'})
    },
  },
  computed: {
    entity() {
      const entity = this.$store.getters['Usuario/selectedEntity']
      return entity && entity.id ? entity : {name: ""}
    },
    thisStablishmentEncounters () {
      return this.encounters.filter( encounter => this.thisStablishment.id == encounter.stablishment_id)
    },
    otherStablishmentsEncounters () {
      return this.encounters.filter( encounter => this.thisStablishment.id != encounter.stablishment_id)
    },
    thisStablishment () {
      return {
        id: 1
      }
    }
  },
  data () {
    return {
      tab: null,
      headers: [
        {value: 'date', text: 'Fecha'},
        {value: 'name', text: 'Tipo'},
        {value: 'stablishment', text: 'Establecimiento'},
        {value: 'responsible.name', text: 'Médico Responsable'},
        {value: '', text: 'Ingreso'},
        {value: '', text: 'Alta'},
        {value: 'specialty', text: 'Especialidad'},
        {value: 'actions', text: 'Acciones'},
      ],
      encounters: [
        {name: 'Nota subsecuente', responsible: {name: 'Carlos Manuel Murillo Tostado'}, stablishment_id: 1, stablishment: "Consultorio Carlos Murillo", type: 1, date: '22/11/2021'},
        {name: 'Nota de primera vez', stablishment_id: 1, stablishment: "Consultorio Carlos Murillo", type: 1, date: '11/11/2021 11:11:00'},
        {name: 'Hospitalización recién nacido', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 3, date: '11/11/2021 11:11:00'},
        {name: 'Hospitalización obstétrica', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 3, date: '11/11/2021 11:11:00'},
        {name: 'Hospitalización médica programada', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 3, date: '11/11/2021 11:11:00'},
        {name: 'Hospitalización médica de urgencia', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 3, date: '11/11/2021 11:11:00'},
          // programada / urgencia
          // ingreso
          // notas de evolución
          // notas de enfermería
          // notas de alta
        {name: 'Hospitalización quirúrgica programada', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 3, date: '11/11/2021 11:11:00'},
        {name: 'Hospitalización quirúrgica de urgencia', stablishment_id: 2, stablishment: "Hospital del Carmen", type: 3, date: '11/11/2021 11:11:00'},
          // - programada / urgencia / ambulatoria
          // ingreso preoperatoria
          // preoperatoria
          // preanestésica
          // operatoria
          // postanestésica
          // reingreso a piso
          // notas de evolución quirúrgica
          // notas de enfermería quirúrgica
          // notas de alta
        {name: 'Urgencias', stablishment_id: 2, stablishment: "Centro Médico de Occidente", type: 1, date: '11/11/2021'},
      ]
    }
  }
}
</script>

<style>

</style>