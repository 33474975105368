<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>MEDICAMENTOS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :items="medicaments" :headers="headers"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    console.log('library')
    this.$store.dispatch('Cie10/libMedicaments')
  },
  computed: {
    medicaments () {
      return this.$store.getters['Cie10/medicaments']
    }
  },
  data () {
    return {
      headers: [
        {text: 'Medicamento', value: 'AppVadMedicamento.name'},
        {text: 'Laboratorio', value: 'AppVadMedCatLaboratorio.name'},
      ]
    }
  }
}
</script>

<style>

</style>