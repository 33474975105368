<template>
  <v-card>
    <v-card-title class="text-h5 grey lighten-2">
      Búsqueda por protocolos
    </v-card-title>

    <v-card-text class="pt-5">
      <v-text-field  label="Palabras Clave" dense outlined v-model="searchField"></v-text-field>
      <v-data-table
        :items='protocols'
        :search="searchField"
        :custom-filter="filter"
        :headers="protocolHeaders"
        item-key="AppCatProtocolo.id"
        show-select
        :single-select="!multipleSelect"
        :items-per-page="6"
      >
      </v-data-table>
      <v-btn class="green lighten-2" dark>Agregar</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  mounted () {
    // let data = {
    //   level: 1
    // }
    // let urlEncodedData = new URLSearchParams(data).toString()
    this.$store.dispatch('ProtocolAPI/protocols', '')
  },
  computed: {
    protocols () {
      return this.$store.getters['ProtocolAPI/protocols']
    }
  },
  watch: {
    searchField () {
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.content)
    },
    filter (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    },
  },
  props: {
    value: {},
    multipleSelect: {
      type: Boolean,
      default: false
    },
    btnIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      searchField: '',
      protocolHeaders: [
        {text:"nombre", value:"AppCatProtocolo.name"},
      ]
    }
  }
}
</script>

<style>

</style>